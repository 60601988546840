import { withDefaultProps } from '@aspectus/vue-utils'
import { Descriptor, NoneController } from '@aspectus/vee-control-descriptor'
import UnifiedControlElement from './UnifiedControlElement'
const Multiselect = () => import('./Multiselect')
import StaticLabel from './StaticLabel'
import ActionComponent from './ActionComponent'

const composerForInput = (input, action, label = StaticLabel) => withDefaultProps({
  labelComponent: () => label,
  element: () => input,
  actionComponent: () => action,
})(UnifiedControlElement)

const composer = input => withDefaultProps({
  element: () => input,
  labelComponent: null,
  bails: true,
  controllerComponent: () => NoneController,
})(Descriptor)

// input
const InputWithLabel = composerForInput('Input')
const ControlInput = composer(InputWithLabel)

// input
const PureInput = composerForInput('Input', null, null)
const ControlPureInput = composer(PureInput)

// input with action
const InputWithAction = composerForInput('Input', ActionComponent)
const ControlInputAction = composer(InputWithAction)

// multiselect
const MultiselectWithLabel = composerForInput(Multiselect, StaticLabel)
const ControlMultiselect = composer(MultiselectWithLabel)

// textarea
const TextareaWithLabel = composerForInput('Textarea')
const ControlTextarea = composer(TextareaWithLabel)

export default function install(Vue, { prefix = 'DControl' } = {}) {
  Vue.component(`${prefix}Input`, ControlInput)
  Vue.component(`${prefix}PureInput`, ControlPureInput)
  Vue.component(`${prefix}InputAction`, ControlInputAction)
  Vue.component(`${prefix}Textarea`, ControlTextarea)
  Vue.component(`${prefix}Multiselect`, ControlMultiselect)
  Vue.component('Multiselect', Multiselect)
}

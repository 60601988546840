<template lang="pug">
  include ./../../../mixins
  div
    ui-dropdown(v-for="(category, i) in categories" :key="i")
      template(#default='{ active, click, arrow }')
        click-outside(:do='active ? click : () => null')
          +b.search-dropdown
            +e.toggler(@click="click")
              row(justify="between" align="center")
                cell(cols="auto")
                  bem-text(
                    tag="p"
                    size="3xs"
                    color="accent-1"
                    variant="bold"
                  ) {{category.title}}
                cell(cols="narrow")
                  row(align="center")
                    cell(cols="narrow")
                      bem-text(
                        tag="p"
                        size="xs"
                        color="accent-1"
                      )
                        span {{ category.count }}
                        span  {{_('products')}}
                    cell(cols="auto")
                      +e.arrow.BEM-ICON(
                        :class="active ? 'is-active' : ''"
                        color="accent-1"
                        size="small"
                        type="chevron-down"
                      )
            +e.wrapper(v-if="active")
              search-filter-item(:subcategories="category.children")
</template>

<script>
import SearchFilterItem from './SearchFilterItem'

export default {
  name: 'search-filter',
  components: {
    SearchFilterItem,
  },
  props: {
    categories: Array,
  },
  // data() {
  //   return {
  //     valItem: false,
  //   }
  // },
}
</script>

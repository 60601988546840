<template lang="pug">
  include ./../../../mixins
  div
    row.ma--bottom_2md
      cell.ma--bottom_2xs(cols="narrow" v-for="(option, i) in productOptions" :key="options.id")
        +b.option-radio
          input(
            type="radio"
            name="option"
            :value="option.id"
            :id="option.id"
            :checked="i === 0"
            @change="sendRequest(option.id)"
          )
          label(:for="option.id") {{  option.title }}
    slot(v-if="!isResponse")
    renderer(
      v-if="isResponse"
      class=""
      :result="response"
    )
</template>

<script>
import { productOptionsResource } from '@/js/services/common'

export default {
  name: 'product-options',
  props: {
    options: String,
  },
  data() {
    return {
      productOptions: JSON.parse(this.options),
      isResponse: false,
      response: '',
    }
  },
  methods: {
    sendRequest(value) {
      return productOptionsResource.execute({ id: value })
        .then(res => {
          this.isResponse = true
          this.response = res.data
        })
    },
  },
}
</script>

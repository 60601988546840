import {
  getResource,
  createResource,
} from '@/js/resource/resource'
import PATH from './path'

export const productFilterResource = createResource(
  PATH.CATALOG.PRODUCT_FILTERS, getResource
)

export const productFilterEmptyResource = createResource(
  PATH.CATALOG.PRODUCT_FILTERS_EMPTY
)

export const productCardResource = createResource(
  PATH.CATALOG.PRODUCTS
)

export const productCardEmptyResource = createResource(
  PATH.CATALOG.PRODUCTS_EMPTY
)

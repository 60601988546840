<template lang="pug">
  include ./../../../mixins
  div
    validation-observer(
      tag="form"
      ref="observer"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit"
    )
      +b.form.ma--bottom_sm
        d-control-input(
          rules="email"
          type="email"
          inputmode="email"
          v-model="formdata.email"
          class-name="form__input"
          label-class="form__label"
          :input-label="_('Your E-mail')"
          :required="true"
          name="email"
        )
      bem-button.ma--top_sm(
        text="title"
        :variant="['fill-accent-1', 'bold', 'uppercase']"
        border="xs"
        space="sm"
        width="full"
        @event="validateBeforeSubmit"
        :disable="isLoad"
      ) {{_('Send')}}
      //- confirmation-code(v-if="isShowCode")
</template>

<script>
import { emailNotConfirmedResource } from '@/js/services/auth'
import FormMixin from './form-mixin'
import ConfirmationCode from './ConfirmationCode'

export default {
  name: 'email-confirmation',
  components: {
    ConfirmationCode,
  },
  mixins: [
    FormMixin,
  ],
  props: {
    id: String,
    tokenItem: String,
  },
  data() {
    return {
      formdata: {
        email: '',
        idSocial: this.id,
        token: this.tokenItem,
      },
    }
  },
  methods: {
    send(data) {
      this.isLoad = true
      return emailNotConfirmedResource.execute({}, data)
        .then(res => {
          this.handleResponse({ modal: false, res, redirect: true })
          this.isLoad = false
        })
    },
  },
}
</script>

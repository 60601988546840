export function accordionHandler() {
  const toggler = Array.from(document.getElementsByClassName('js-acc-toggler'))
  const content = Array.from(document.getElementsByClassName('js-content'))

  const closeAccordions = () => {
    toggler.forEach(el => {
      el.classList.remove('is-active')
    })
    content.forEach(el => {
      el.classList.remove('is-active')
    })
  }

  toggler.forEach((el, i) => {
    el.onclick = e => {
      if (!e.target.href) {
        if (el.classList.contains('is-active')) {
          closeAccordions()
        } else {
          closeAccordions()
          content[i].classList.add('is-active')
          el.classList.add('is-active')
        }
      }
    }
  })
}

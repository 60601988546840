<template lang="pug">
  include ./../../../mixins
  div.position--relative
    preloader(v-if="isLoad")
    row.ma--bottom_3lg(space="md-xxl")
      +b.filter-btn.ma--center.ma--bottom_sm.u-display--hidden-lg.BUTTON(@click="toggleFilter") {{_('Filter')}}
      cell(cols="12 3-lg")
        +b.card--color_white.--variant_fullheight.filter-wrapper(:class="{'is-active': isShowFilters == true}")
          +b.filter-close.u-display--hidden-lg.BUTTON(@click="toggleFilter")
            bem-icon.z-index--2(
              type="close"
              size="xs"
              color="accent-1"
              variant="right"
              @click="toggleFilter"
            )
          +b.card.--space_sm.--space_2sm-lg.ma--top_sm-till-lg
            //-filter
            row(align="center")
              cell(cols="auto")
                bem-text(
                  tag="h3"
                  appearance="title"
                  color="accent-1"
                  variant="uppercase"
                  size="3sm"
                ) {{_('FILTERS')}}
              cell(cols="narrow")
                +b.clear-btn.BUTTON(
                  @click="clearFilters"
                  @mouseover="isProcessingClear = true"
                  @mouseleave="isProcessingClear = false"
                  :class="{'is-active': isProcessingClear}"
                ) {{_('Clear filters')}}
          .border
          +b.card.--space_2sm
            filter-search(
              :params="paramsObj"
              @search="paramsObj = $event; requestForCards()"
            )
            filter-section(
              :filter-data="filterData"
              :params="paramsObj"
              @update-params="paramsObj = $event; requestForCards()"
            )
            //- p {{window}}
            row.ma--top_2sm(v-if="isShowCheckbox == 'True'")
              cell.ma--bottom_sm-till-xl(cols="12 6-xl")
                +b.filter-checkbox
                  input(
                    type="checkbox"
                    v-model="tracked"
                    id="tracked"
                    checked="true"
                    @change="addCheckboxValue"
                    :disabled="!trackedActivity"
                  )
                  label(for="tracked")
                    bem-text(
                      tag="p"
                      appearance="title"
                      color="white"
                    ) {{_('Tracked')}}
                    bem-icon(
                      type="notification"
                      size="md"
                      color="accent-1"
                    )
              cell.ma--bottom_sm-till-xl(cols="12 6-xl")
                +b.filter-checkbox
                  input(
                    type="checkbox"
                    v-model="favorite"
                    id="favorite"
                    checked="true"
                    @change="addCheckboxValue"
                    :disabled="!favoriteActivity"
                  )
                  label(for="favorite")
                    bem-text(
                      tag="p"
                      appearance="title"
                      color="white"
                    ) {{_('Favorite')}}
                    bem-icon(
                      type="star"
                      size="md"
                      color="accent-1"
                    )
      cell(cols="12 9-lg")
        //-cards
        row.ma--bottom_3lg(v-if="!isResponse" space="sm-xxl")
          slot
        renderer.ma--bottom_2md(
          v-if="isResponse"
          :result="response"
          class-render='g-row g-row--space_sm-xxl'
        )
        bem-text(
          appearance="title"
          tag="p"
          size="3sm"
          align="center"
          v-if="isResponse && response.length === 0"
        ) {{_('There are no products')}}
        row(justify="center")
          cell(cols="12 9-lg 7-xxl")
            pagination(
              v-if="count > perPage"
              class-wrapper="pagination__wrapper--white"
              :is-scroll="true"
              :current='page'
              :total='count'
              :perPage='perPage'
              @page-changed="page = $event, requestForCards($event)"
            )
</template>

<script>
import Preloader from '@/js/components/UiElements/Preloader'
import {
  productFilterResource,
  productFilterEmptyResource,
  productCardResource,
  productCardEmptyResource,
} from '@/js/services/catalog'
import { prefixLanguage } from '@/js/helpers/url'
import FilterSection from './FilterSection'
import CatalogMixin from './catalog-mixin'
import FilterSearch from './FilterSearch'

const one = 1

export default {
  name: 'product-catalog',
  components: {
    FilterSection,
    Preloader,
    FilterSearch,
  },
  mixins: [
    CatalogMixin,
  ],
  props: {
    pagination: Object,
    category: String,
  },
  data() {
    return {
      tracked: false,
      favorite: false,
      isProcessingClear: false,
      paramsObj: {},
      filterData: [],
      isResponse: false,
      response: '',
      isLoad: false,
      count: this.pagination.count,
      page: this.pagination.page,
      perPage: this.pagination.perPage,
      favoriteActivity: true,
      trackedActivity: true,
      isShowCheckbox: window.is_auth,
      search: '',
    }
  },
  mounted() {
    this.checkUrl(this.category)
    // this.formatFilter()
    this.getFilters()
  },
  created() {
    window.onpopstate = async () => {
      window.location.reload()
    }
  },
  methods: {
    getFilters() {
      this.isLoad = true
      return productFilterResource.execute({
        category: this.category,
        filters: this.paramsObj,
        page: this.page,
      })
        .then(res => {
          this.filterData = this.formatFilters(res.data.items)
          this.favoriteActivity = res.data.favorite
          this.trackedActivity = res.data.tracker
          this.isLoad = false
        })
    },
    requestForCards(page = one) {
      let cardsResource = productCardResource
      if (0 === Object.keys(this.paramsObj).length) {
        cardsResource = productCardEmptyResource
      }
      this.isLoad = true
      // this.paramsObj.search = [this.paramsObj.search]
      Promise.all([
        cardsResource.execute({
          category: this.category,
          filters: this.paramsObj,
          page,
        }),
      ])
        .then(res => {
          this.handleCards(res[0])
          this.pushHistory()
          this.isProcessingClear = false
          this.isLoad = false
        })
    },
    handleFilters(res) {
      this.filterData = this.formatFilters(res.data.items)
      this.page = 1
    },
    async clearFilters() {
      this.isProcessingClear = true
      this.paramsObj = {}
      this.requestForCards()
      this.favorite = false
      this.tracked = false
    },
    pushHistory() {
      const baseUrl = `${prefixLanguage(window.location.origin)}/${this.category}/`
      let page
      if (1 < this.page) {
        page = `page/${this.page}/`
      } else {
        page = ''
      }
      window.history.pushState(null, null, `${baseUrl}${this.generateUrl(this.paramsObj)}${page}`)
    },
    addCheckboxValue() {
      if (true === this.favorite) this.paramsObj.favorite = [this.favorite]
      else delete this.paramsObj.favorite
      if (true === this.tracked) this.paramsObj.tracked = [this.tracked]
      else delete this.paramsObj.tracked
      this.requestForCards()
    },
  },
}
</script>

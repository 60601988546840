import Vuex from 'vuex'
import { customerCabinet } from './modules/customer-cabinet/index'
import { common } from './modules/common/index'
import { managerCabinet } from './modules/manager-cabinet/index'

export function createStore() {
  return new Vuex.Store({
    deep: true,
    modules: {
      customerCabinet,
      managerCabinet,
      common,
    },
  })
}

<template lang="pug">
  include ./../../../mixins
  div
    +b.search-dropdown(v-for="subcategory in subcategories")
      +e.item-wrapper
        +e.item.position--relative
          +b.absolute-link.A(:href="subcategory.url")
          row(justify="between" align="center")
            cell(cols="auto")
              +e.title.BEM-TEXT(
                tag="p"
                size="3xs"
                color="accent-1"
                variant="bold"
              ) {{ subcategory.title }}
            cell(cols="narrow")
              bem-text(
                tag="p"
                size="xs"
                color="accent-1"
              )
                span {{ subcategory.count }}
                span  {{_('products')}}
</template>

<script>
export default {
  name: 'search-filter-item',
  props: {
    subcategories: Array,
  },
}
</script>

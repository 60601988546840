// ios scroll
const body = document.querySelector('body')
let scrollPosition = 0
const zero = 0

export const scrollUnlock = () => {
  body.style.removeProperty('overflow')
  body.style.removeProperty('position')
  body.style.removeProperty('top')
  body.style.removeProperty('width')
  window.scrollTo(zero, scrollPosition)
}

export const scrollLock = () => {
  scrollPosition = window.pageYOffset
  // body.style.overflow = 'hidden'
  body.style.position = 'fixed'
  body.style.top = `-${scrollPosition}px`
  body.style.width = '100%'
}

<template lang="pug">
  include ./../../../mixins
  div
    component(
      :is-bound="isBound",
      :is='labelComponent',
      :id='id',
      :img="img"
      :value='value',
      :labelText='labelText',
      :input-label='inputLabel',
      :is-required='required'
      :label-class="labelClass"
      v-if="!withoutLabel"
    )
    row(align="center" space="none")
      cell(cols="auto")
        component(
          :is="element",
          :value.prop='value',
          :checked.prop='value'
          ref='input'
          v-on="listeners"
          v-bind='$attrs'
          :type='type'
          v-model='model',
          :class="className"
          :id="id"
          @focus="isBound = true"
          @blur="isBound = false"
        )
        +b.inner-control.mdi.A(
          href='',
          v-if='type == "password" '
          @click.prevent='changeType()',
          :class='show ? "icon--type_eye text--size_2xs display_block" : "icon--type_eye-off display_block"'
        )
        +b.inner-control--centered(
          v-if='type == "search" '
          class="icon--type_search text--size_2xs display_block"
        )
        +b.inner-control--close(
          v-if='type == "search" && value && value.length > 0'
          class="icon--type_close text--size_2xs display_block"
          @click="$emit('clear')"
        )
      cell(cols="narrow" v-if="actionComponent")
        component(
          v-if="true"
          :is='actionComponent'
          @event="$emit('delete', $event)"
        )
</template>

<script>
export default {
  name: 'element-input',
  props: [
    'labelComponent',
    'actionComponent',
    'id',
    'img',
    'value',
    'labelText',
    'inputLabel',
    'required',
    'providerContext',
    'type',
    'element',
    'className',
    'labelClass',
    'withoutLabel',
  ],
  watch: {
    value: {
      handler() {
        this.input = this.value
      },
      deep: true,
    },
  },
  data() {
    return {
      input: '',
      show: false,
      isBound: false,
    }
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: $event => {
          this.$emit('input', this.getEvent($event))
        },
      }
    },
    model: {
      get() {
        return this.input
      },
      set(e) {
        this.input = e
      },
    },
  },
  methods: {
    getKey() {
      return 'checkbox' === this.type ? 'checked' : 'value'
    },
    changeType() {
      this.show = !this.show
      if (this.show) {
        this.$refs.input.type = 'text'
      } else {
        this.$refs.input.type = this.type
      }
    },
    getEvent(event) {
      if (event && event.type) {
        return event.target[this.getKey()]
      }
      return event
    },
  },
}
</script>

<template lang="pug">
  include ./../../../mixins
  div
    +b.icon--type_close.--size_xs.modal-close--common.BUTTON(tabindex="-1" @click="$emit('close')")
    bem-text.ma--bottom_sm(
      tag="h2"
      appearance="title"
      color="accent-1"
      size="md"
      height="sm"
      :variant="['bold', 'center']"
    ) {{ objectItem.title }}
    bem-text.ma--bottom_sm(
      tag="p"
      color="accent-1"
      size="3xs"
      height="sm"
      variant='center'
    ) {{_('inform if the product price is cheaper than:')}}
    validation-observer(
      tag="form"
      ref="observer"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit"
    )
      +b.form.ma--bottom_sm
        d-control-pure-input(
          rules="required|decimal_dollar"
          placeholder="$"
          type="text"
          :value="price"
          @input="formdata.expectedPrice = $event.replace('$', '')"
          class-name="form__input"
          name="expectedPrice"
        )
      +b.form.ma--bottom_sm
        d-control-pure-input(
          rules="email|required"
          :placeholder="_('E-mail')"
          type="email"
          inputmode="email"
          v-model="formdata.email"
          class-name="form__input"
          name="email"
        )
      bem-button(
        text="title"
        :variant="['fill-accent-1', 'bold', 'uppercase']"
        border="xs"
        space="sm"
        width="full"
        @event="validateBeforeSubmit; sendTag()"
        :disable="isLoad"
      ) {{_('track the price')}}
</template>

<script>
import { trackProductResource } from '@/js/services/actions'
import { mapActions, mapGetters } from 'vuex'
import FormMixin from './form-mixin'

export default {
  name: 'tracked-form',
  mixins: [FormMixin],
  props: {
    objectItem: Object,
  },
  data() {
    return {
      formdata: {
        expectedPrice: this.objectItem.price,
        email: '',
        idVariant: this.objectItem.idVariant,
      },
      price: '',
    }
  },
  watch: {
    'formdata.expectedPrice': {
      handler(nval) {
        this.price = this.addDollar(nval)
      },
      immediate: true,
    },
  },
  async mounted() {
    await this.getUserRequest()
    this.formdata.email = this.user.email
  },
  methods: {
    ...mapActions('customerCabinet', [
      'getUserRequest',
    ]),
    send(data) {
      this.isLoad = true
      return trackProductResource.execute({}, data)
        .then(res => {
          this.handleResponse({ modal: true, res })
          this.isLoad = false
        })
    },
    sendTag() {
      /* eslint-disable no-undef */
      gtag('event', 'click', { event_category: 'Track-price' })
      /* eslint-enable no-undef */
    },
  },
  computed: {
    ...mapGetters('customerCabinet', {
      user: 'getUserInfo',
    }),
    addDollar() {
      return function (val) {
        return `$${val}`
      }
    },
  },
}
</script>

import CabinetNavigationOutside from './CabinetNavigationOutside'
import FeatureWarning from './Common/FeatureWarning'

const UsersRow = () => import('./Table/UsersRow')
const SubscriptionRow = () => import('./Table/SubscriptionRow')
const ManagerRequestsRow = () => import('./Table/ManagerRequestsRow')
const ContactRequestRow = () => import('./Table/ContactRequestRow')
const CabinetNavigation = () => import('./CabinetNavigation')
const CatalogRow = () => import('./Table/CatalogRow')
const ProductRow = () => import('./Table/ProductRow')
const RequestRow = () => import('./Table/RequestRow')
const TrackedRow = () => import('./Table/TrackedRow')

export default function install(Vue) {
  Vue.component(CabinetNavigationOutside.name, CabinetNavigationOutside)
  Vue.component(FeatureWarning.name, FeatureWarning)
  Vue.component('CabinetNavigation', CabinetNavigation)
  Vue.component('SubscriptionRow', SubscriptionRow)
  Vue.component('UsersRow', UsersRow)
  Vue.component('ManagerRequestsRow', ManagerRequestsRow)
  Vue.component('ContactRequestRow', ContactRequestRow)
  Vue.component('TrackedRow', TrackedRow)
  Vue.component('CatalogRow', CatalogRow)
  Vue.component('RequestRow', RequestRow)
  Vue.component('ProductRow', ProductRow)
}

import NewPassword from './NewPasswordForm'
import EmailConfirmation from './EmailConfirmation'
import ChangePassword from './ChangePassword'
import TrackedForm from './TrackedForm'
import RequestForm from './RequestForm'
import FinanceForm from './FinanceForm'
import ServiceActivatingForm from './ServiceActivatingForm'
import ServicePeriodForm from './ServicePeriodForm'

const SubscriptionForm = () => import('./SubscriptionForm')
const AddCatalogForm = () => import('./AddCatalogForm')
const RegistrationForm = () => import('./RegistrationForm')
const AuthForm = () => import('./AuthForm')
const ContactForm = () => import('./ContactForm')
const ContactFormModal = () => import('./ContactFormModal')

export default function install(Vue) {
  Vue.component('ContactForm', ContactForm)
  Vue.component('ContactFormModal', ContactFormModal)
  Vue.component('RegistrationForm', RegistrationForm)
  Vue.component(NewPassword.name, NewPassword)
  Vue.component(EmailConfirmation.name, EmailConfirmation)
  Vue.component('AuthForm', AuthForm)
  Vue.component(ChangePassword.name, ChangePassword)
  Vue.component(TrackedForm.name, TrackedForm)
  Vue.component('AddCatalogForm', AddCatalogForm)
  Vue.component(RequestForm.name, RequestForm)
  Vue.component('SubscriptionForm', SubscriptionForm)
  Vue.component(FinanceForm.name, FinanceForm)
  Vue.component(ServiceActivatingForm.name, ServiceActivatingForm)
  Vue.component(ServicePeriodForm.name, ServicePeriodForm)
}

import { prefixAPI, baseAPI } from '@/js/resource/api.js'

export default {
  CATALOG: {
    PRODUCT_FILTERS: prefixAPI('filter/{category}/list/{;filters*}/page/{page}/'),
    PRODUCT_FILTERS_EMPTY: prefixAPI('filter/{category}/list/page/{page}/'),
    PRODUCTS: baseAPI('/{category}/catalog-card/{;filters*}/page/{page}'),
    PRODUCTS_EMPTY: baseAPI('/{category}/catalog-card/page/{page}'),
  },
  CATALOG_PDF: {
    PDF_FILTERS: prefixAPI('catalog-pdf/filter-pdf/{;filters*}/page/{page}/'),
    PDF_FILTERS_EMPTY: prefixAPI('catalog-pdf/filter-pdf/page/{page}/'),
    PDF_LIST: baseAPI('/catalog-file-ajax/{;filters*}/page/{page}'),
    PDF_LIST_EMPTY: baseAPI('/catalog-file-ajax/page/{page}'),
  },
  STAND_PRODUCTS: {
    STAND_FILTERS: prefixAPI('filter-stand/{slug}/{;filters*}/page/{page}/'),
    STAND_FILTERS_EMPTY: prefixAPI('filter-stand/{slug}/page/{page}/'),
    STAND_LIST: baseAPI('/stand-ajax/{slug}/{;filters*}/page/{page}'),
    STAND_LIST_EMPTY: baseAPI('/stand-ajax/{slug}/page/{page}'),
    // STANDS_PAGE_CATALOG: baseAPI('/stand/{;filters*}/page/{page}/'),
    STANDS_PAGE_CARDS: baseAPI('/stands-ajax/{;filters*}/page/{page}/'),
    STANDS_PAGE_CARDS_EMPTY: baseAPI('/stands-ajax/page/{page}/'),
  },
  COMMON: {
    CONTACT: prefixAPI('contact/feedback/'),
    PRODUCT_OPTIONS: '/product-variant/{id}/',
  },
  AUTH: {
    REGISTRATION: prefixAPI('auth/registration/'),
    AUTH: prefixAPI('auth/login/'),
    LOGOUT: prefixAPI('auth/logout/'),
    RESET: prefixAPI('auth/password/reset/'),
    NEW_PASSWORD: prefixAPI('auth/password/reset/confirm/'),
    CHANGE_PASSWORD: prefixAPI('auth/password/change/'),
    EMAIL_NOT_CONFIRMED: prefixAPI('auth/set-email/'),
  },
  ACTIONS: {
    FAVORITE: prefixAPI('like/turn/'),
    ADD_COMPARE: prefixAPI('comparison/turn/'),
    DELETE_CATEGORY: prefixAPI('comparison/drop-category/'),
    DELETE_PRODUCT: prefixAPI('comparison/drop-product/'),
    TRACK_PRODUCT: prefixAPI('tracker-product/create/'),
  },
  COMPARISON: {
    COMPARE_PRODUCTS: prefixAPI('comparison/category-detail/{slug}'),
  },
  COMMON_CABINET: {
    CATEGORIES: prefixAPI('root-categories/'),
    SUBCATEGORIES: prefixAPI('child-categories/'),
    COUNTRIES: prefixAPI('list-country'),
    PRICE_DESCRIPTION: prefixAPI('product/descriptions-price/'),
    ATTRIBUTES: prefixAPI('list-values-attribute/'),
    USER_LIST: prefixAPI('features/request/filters/'),
  },
  CABINET: {
    CREATE_CATALOG: prefixAPI('catalog-pdf/add/'),
    CREATE_PRODUCT: prefixAPI('product/add/'),
    PRODUCT_LIST: prefixAPI('product/list/{;filters*}/page/{page}/'),
    PRODUCT_UPDATE: prefixAPI('product/update/{id}/'),
    PRODUCT_DETAIL: prefixAPI('product/detail/{id}/'),
    PRODUCT_DELETE: prefixAPI('product/delete/'),
    PRODUCT_VISIBILITY: prefixAPI('product/turn/'),
    FAVORITE_LIST: baseAPI('/catalog-card/favorite/page/{page}/'),
    PRODUCT_CATEGORY: prefixAPI('product/root-categories/'),
    PRODUCT_SUBCATEGORY: prefixAPI('product/child-categories/'),
  },
  CABINET_SETTINGS: {
    SETTINGS_USER_DETAIL: prefixAPI('cabinet/user-detail/'),
    SETTINGS_SHOP_DETAIL: prefixAPI('shop-detail/'),
    SETTINGS_USER_CREATE: prefixAPI('shop-create/'),
    SETTINGS_USER_UPDATE: prefixAPI('cabinet/user-update/'),
    SETTINGS_SHOP_UPDATE: prefixAPI('shop-update/'),
  },
  CABINET_CATALOG: {
    CATALOG_LIST: prefixAPI('catalog-pdf/list/page/{page}/'),
    CATALOG_ADD: prefixAPI('catalog-pdf/add/'),
    CATALOG_UPDATE: prefixAPI('catalog-pdf/update/{id}/'),
    CATALOG_STATUS: prefixAPI('catalog-pdf/turn/'),
    CATALOG_DELETE: prefixAPI('catalog-pdf/delete/'),
  },
  REQUEST: {
    REQUEST_ADD: prefixAPI('query/add/'),
    REQUEST_SENT_LIST: prefixAPI('query/list-sent/{;search*}/page/{page}/'),
    REQUEST_GOT_LIST: prefixAPI('query/list-got/{;search*}/page/{page}/'),
    REQUEST_DETAIL: prefixAPI('query/detail/{id}/'),
    REQUEST_ANSWER: prefixAPI('query/set-answer/'),
    REQUEST_IS_READ: prefixAPI('query/set-read/'),
    REQUEST_IS_READ_ANSWER: prefixAPI('query/set-read-answer/'),
    REQUEST_NOTIFICATION: prefixAPI('query/sent-got-counter/'),
  },
  SEARCH: {
    DYNAMIC_SEARCH: prefixAPI('product/search-header/'),
    SEARCH_CATEGORIES: prefixAPI('product/search-categories/{;search*}/'),
    CARD_LIST: baseAPI('/search-ajax/{;search}/page/{page}/'),
  },
  FEATURES: {
    FEATURE_CREATE: prefixAPI('features/request/add/'),
    FEATURE_LIST: prefixAPI('features/list/'),
    FEATURE_CALCLATE: prefixAPI('features/request/calculate/'),
    FEATURE_CUSTOMER_LIST: prefixAPI('features/request/list/{;search*}/page/{page}/'),
    FEATURE_MANAGER_LIST: prefixAPI('features/request/list-manager/{;filters*}/page/{page}/'),
    FEATURE_MANAGER_DETAIL: prefixAPI('features/request/detail-manager/'),
    FEATURE_MANAGER_UPDATE: prefixAPI('features/request/update/'),
  },
  TRACKED_PRODUCTS: {
    TRACKED_LIST: prefixAPI('tracker-product/list/{;filters*}/page/{page}/'),
    CATEGORIES_LIST: prefixAPI('tracker-product/root-categories/'),
    SUBCATEGORIES_LIST: prefixAPI('tracker-product/child-categories/'),
    TRACKED_HIDE: prefixAPI('tracker-product/hide/'),
  },
  SUBSCRIPTIONS: {
    CATEGORY_NEWS_LIST: prefixAPI('subscribe/category-news/'),
    CATEGORY_CATALOG_LIST: prefixAPI('subscribe/category-catalog/'),
    SUBSCRIBE_NEWS: prefixAPI('subscribe/create-news/'),
    SUBSCRIBE_CATALOG: prefixAPI('subscribe/create-catalog/'),
    SUBSCRIBE_DELETE: prefixAPI('subscribe/delete/'),
    SUBSCRIPTION_LIST: prefixAPI('subscribe/list/page/{page}/'),
  },
  DASHBOARD: {
    DASHBOARD_DATA: prefixAPI('statistics/'),
  },
  MANAGER: {
    MANAGER_NOTIFICATION: prefixAPI('manager/notification/'),
    USER_LIST: prefixAPI('manager/users/list/{;filters*}/page/{page}/'),
    USER_DETAIL: prefixAPI('manager/users/profile/detail/'),
    USER_UPDATE: prefixAPI('manager/users/profile/update/'),
    STORE_DETAIL: prefixAPI('manager/users/shop/detail/'),
    STORE_UPDATE: prefixAPI('manager/users/shop/update/'),
    STORE_CREATE: prefixAPI('manager/users/shop/create/'),
    PRODUCT_LIST: prefixAPI('manager/users/product/{id}/list/{;filters*}/page/{page}/'),
    PRODUCT_CATEGORIES: prefixAPI('manager/users/product/{id}/root-categories/'),
    PRODUCT_SUBCATEGORIES: prefixAPI('manager/users/product/{id}/child-categories/'),
    PRODUCT_CREATE: prefixAPI('manager/users/product/create/'),
    SERVICES_LIST: prefixAPI('manager/users/feature/{id}/list/'),
    SERVICE_FREE: prefixAPI('manager/users/feature/{id}/set-free/'),
    MANAGER_QUESTIONS: prefixAPI('manager/feed-back/list/{;search*}/page/{page}/'),
    DETAIL_QUESTIONS: prefixAPI('manager/feed-back/detail/{id}/'),
    SET_QUESTIONS: prefixAPI('manager/feed-back/set-answer/'),
  },
}

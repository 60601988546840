<template lang="pug">
  include ./../../../mixins
  div
    template(v-for="(select, i) in filterData")
      filter-item(
        :select="select"
        :params="params"
        :index="i"
        @update-params="$emit('update-params', $event)"
      )
      //- p {{params}}
</template>

<script>
import FilterItem from './FilterItem'

export default {
  name: 'filter-section',
  components: {
    FilterItem,
  },
  props: {
    filterData: Array,
    params: Object,
  },
}
</script>

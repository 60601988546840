<template lang="pug">
  include ./../../../mixins
  div
    +b.icon--type_close.--size_xs.modal-close--common.BUTTON(
      tabindex="-1"
      @click="$emit('close')"
    )
    bem-text.ma--bottom_2sm(
      tag="h2"
      appearance="title"
      color="accent-1"
      size="md"
      height="xs"
      variant='bold'
    ) {{_('You want to change the number of days of service')}}
    validation-observer(
      tag="form"
      ref="observer"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit"
    )
      +b.form.ma--bottom_sm
        d-control-input(
          rules="required|numeric"
          type="number"
          v-model="formdata.countDays"
          class-name="form__input"
          label-class="form__label"
          :input-label="_('Amount of days')"
          :required="true"
          name="days"
        )
      +b.error-text.ma--bottom_sm.--top_sm.P(
        v-for="error in fieldErrors"
        v-if="fieldErrors.length > 0"
      ) {{ error }}
      bem-button(
        text="title"
        :variant="['fill-accent-1', 'bold', 'uppercase']"
        border="xs"
        space="sm"
        width="full"
        @event="validateBeforeSubmit"
      ) {{_('Change')}}
</template>

<script>
import {
  featureManagerUpdateResource,
} from '@/js/services/features'
import FormMixin from './form-mixin'

export default {
  name: 'service-period-form',
  mixins: [
    FormMixin,
  ],
  props: {
    objectItem: Object,
  },
  data() {
    return {
      formdata: {
        countDays: this.objectItem.count,
        idRequest: this.objectItem.id,
      },
    }
  },
  methods: {
    send(data) {
      return featureManagerUpdateResource.execute({}, data)
        .then(res => {
          this.handleResponse({ modal: true, res })
        })
    },
    updateValidator(errors) {
      // const parsed = defaultValidatorErrorsParser(errors)
      // this.$refs.observer.setErrors(parsed)
      this.fieldErrors = [errors.nonFieldErrors.message]
    },
  },
}
</script>

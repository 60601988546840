import ProductCatalog from './ProductCatalog'
import StandCatalog from './StandCatalog'
import PdfCatalog from './PdfCatalog'
// import FilterSection from './FilterSection'
import SearchCatalog from './SearchCatalog'
// import SearchFilter from './SearchFilter'
const StandsListCatalog = () => import('./StandsListCatalog')

export default function install(Vue) {
  Vue.component(ProductCatalog.name, ProductCatalog)
  Vue.component(StandCatalog.name, StandCatalog)
  Vue.component(PdfCatalog.name, PdfCatalog)
  Vue.component(SearchCatalog.name, SearchCatalog)
  Vue.component('StandsListCatalog', StandsListCatalog)
  // Vue.component(SearchFilter.name, SearchFilter)
}

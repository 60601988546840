export function djangoToJsBoolean(variable) {
  return 'True' === variable
}

export function valueFromSelect(item, key) {
  let res
  if (item.length) {
    res = []
    item.forEach(el => {
      res.push(el[key])
    })
  } else {
    res = item[key]
  }
  return res
}

export function restoreObject(arr, id) {
  let item
  if ('object' === typeof id) {
    item = []
    id.forEach(el => {
      item.push(arr.filter(itemEl => itemEl.id === el))
    })
  } else {
    item = arr.filter(itemEl => itemEl.id === id)
  }
  return item
}

export function getTitleById(arr, id) {
  const res = arr.filter(item => id === item.id)[0]
  return res.title
}

export function hasKey(obj, key) {
  return Object.prototype.hasOwnProperty.call(obj, key)
}

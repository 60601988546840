<template lang="pug">
  include ./../../../mixins
  div
    +b.icon--type_close.--size_xs.modal-close--common.BUTTON(@click="$emit('close')")
    bem-text.ma--bottom_3sm.ma--top_3sm(
      tag="h3"
      color="accent-1"
      size="sm"
      height="sm"
      :variant="['center', 'bold']"
    ) {{_('To use this function through the finance section and pay for the service.')}}
    bem-button(
      text="title"
      :variant="['fill-accent-1', 'bold', 'uppercase']"
      border="xs"
      space="3xs"
      width="full"
      @event="$router.push({ name: 'customer-finance'}); $emit('close')"
    ) {{_('Go to the available services')}}
</template>

<script>
export default {
  name: 'feature-modal',
}
</script>

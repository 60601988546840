// libraries
// const VueSocialSharing = require('vue-social-sharing')
import VueSocialSharing from 'vue-social-sharing'
import GridPlugin from '@aspectus/vue-grid'
import CKEditor from '@ckeditor/ckeditor5-vue'
import VModal from 'vue-js-modal/dist/index.nocss.js'

import VueAnimate from 'vue-animate-scroll'
// custom components
import UiElelments from './UiElements'
import Searches from './Search'
import Catalog from './Catalog'
import Controls from './Controls'
import Bem from './bem'
import Forms from './Forms'
import Modals from './Modals'
import Comparison from './Comparison'
import Cabinet from './Cabinet'
import Actions from './Actions'

export default function plugin(Vue) {
  Vue.use(GridPlugin)
  Vue.use(VModal, {
    dynamic: true,
    dialog: true,
    dynamicDefaults: {
      height: 'auto',
      clickToClose: true,
      scrollable: false,
      transition: 'fade',
      overlayTransition: 'fade',
    },
  })
  Vue.use(UiElelments)
  Vue.use(Searches)
  Vue.use(VueSocialSharing)
  Vue.use(VueAnimate)
  Vue.use(Catalog)
  Vue.use(Bem)
  Vue.use(Controls)
  Vue.use(Forms)
  Vue.use(Modals)
  Vue.use(Actions)
  Vue.use(Comparison)
  Vue.use(Cabinet)
  Vue.use(CKEditor)
}

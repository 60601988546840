import {
  getResource,
  createResource,
  postResource,
} from '@/js/resource/resource'
import PATH from './path'

export const subscriptionsListResource = createResource(
  PATH.SUBSCRIPTIONS.SUBSCRIPTION_LIST, getResource
)

export const categoriesNewsListResource = createResource(
  PATH.SUBSCRIPTIONS.CATEGORY_NEWS_LIST, getResource
)

export const categoriesCatalogListResource = createResource(
  PATH.SUBSCRIPTIONS.CATEGORY_CATALOG_LIST, getResource
)

export const subscribeNewsResource = createResource(
  PATH.SUBSCRIPTIONS.SUBSCRIBE_NEWS, postResource
)

export const subscribeCatalogResource = createResource(
  PATH.SUBSCRIPTIONS.SUBSCRIBE_CATALOG, postResource
)

export const deleteSubscriptionResource = createResource(
  PATH.SUBSCRIPTIONS.SUBSCRIBE_DELETE, postResource
)

<template lang="pug">
  include ./../../../mixins
  +b.video
    +e.content-wrapper
      +e.img.position--absolute_full.IMG(:src='preview || getVideoPreview(video)')
      +e.mask.position--absolute_full
      modal-mixin(
        component-name="video-modal"
        :object-item="{src: getVideo(video)}"
        modal-class="modal modal--full-container modal--size_md modal--container"
      )
        template(#default="{handler}")
          +e.button.position--absolute_center.BUTTON(@click="handler")
            ui-icon(
              type="play-circle"
              size="xl"
              color="white"
            )
</template>

<script>
export default {
  name: 'video-wrapper',
  props: {
    video: {
      type: String,
    },
    preview: {
      type: String,
    },
  },
  data() {
    return {
      videoLink: this.getVideo(this.video),
    }
  },
  methods: {
    getVideoId(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
      const match = url.match(regExp)
      const eleven = 11
      if (match && match[2].length === eleven) {
        return match[2]
      }
      return 'error'
    },
    getVideoPreview(url) {
      const videoPreview = `//img.youtube.com/vi/${this.getVideoId(url)}/0.jpg`
      return videoPreview
    },
    getVideo(el) {
      const item = `//www.youtube.com/embed/${this.getVideoId(el)}?autoplay=1`
      return item
      // this.videoLink = item
    },
  },
}
</script>

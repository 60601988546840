import {
  MANAGER_BASE_PATH,
  MANAGER_USERS_LIST_PATH,
  MANAGER_USER_PATH,
  MANAGER_USER_GENERAL_PATH,
  MANAGER_USER_STORE_PATH,
  MANAGER_USER_PRODUCTS_PATH,
  MANAGER_USER_SERVICES_PATH,
  MANAGER_USER_PRODUCT_ADD_PATH,
  MANAGER_REQUESTS_LIST_PATH,
  MANAGER_REQUESTS_VIEW_PATH,
  MANAGER_CONTACT_LIST_PATH,
  MANAGER_CONTACT_VIEW_PATH,
} from '@/js/components/path'

import { managerProductCreateResource } from '@/js/services/manager'

export const routes = [
  {
    path: MANAGER_BASE_PATH,
    redirect: () => MANAGER_USERS_LIST_PATH,
    component: () => import('@/js/components/Cabinet/ManagerOrigin'),
    meta: {
      userRole: 'manager',
    },
    children: [
      {
        path: MANAGER_USERS_LIST_PATH,
        component: () => import('@/js/components/Cabinet/Manager/ManagerUserList'),
        name: 'manager-user-list',
      },
      {
        path: MANAGER_USER_PATH,
        redirect: () => MANAGER_USER_GENERAL_PATH,
        component: () => import('@/js/components/Cabinet/Manager/ManagerUserSettings'),
        name: 'manager-user-settings',
        children: [
          {
            path: MANAGER_USER_GENERAL_PATH,
            component: () => import('@/js/components/Cabinet/Wrappers/ManagerUserFormWrapper'),
            name: 'manager-user-general',
          },
          {
            path: MANAGER_USER_STORE_PATH,
            component: () => import('@/js/components/Cabinet/Wrappers/ManagerStoreFormWrapper'),
            name: 'manager-user-store',
          },
          {
            path: MANAGER_USER_PRODUCTS_PATH,
            component: () => import('@/js/components/Cabinet/Wrappers/ManagerCabinetProductMain'),
            name: 'manager-user-product',
          },
          {
            path: MANAGER_USER_PRODUCT_ADD_PATH,
            component: () => import('@/js/components/Cabinet/Common/CabinetProductCreate'),
            name: 'manager-user-add-product',
            props: {
              createResource: managerProductCreateResource,
              productListPath: 'manager-user-product',
            },
          },
          {
            path: MANAGER_USER_SERVICES_PATH,
            component: () => import('@/js/components/Cabinet/Table/ManagerServicesTable'),
            name: 'manager-user-services',
          },
        ],
      },
      {
        path: MANAGER_REQUESTS_LIST_PATH,
        component: () => import('@/js/components/Cabinet/Manager/ManagerRequestsList'),
        name: 'manager-request-list',
      },
      {
        path: MANAGER_REQUESTS_VIEW_PATH,
        component: () => import('@/js/components/Cabinet/Manager/ManagerRequestView'),
        name: 'manager-request-view',
      },
      {
        path: MANAGER_CONTACT_LIST_PATH,
        component: () => import('@/js/components/Cabinet/Manager/ManagerContactList'),
        name: 'manager-contact-list',
      },
      {
        path: MANAGER_CONTACT_VIEW_PATH,
        component: () => import('@/js/components/Cabinet/Manager/ManagerContactView'),
        name: 'manager-contact-view',
      },
      {
        path: '*',
        component: () => import('@/js/components/Cabinet/NotFound'),
      },
    ],
  },
]

function checkSidebarActivity() {
  if ('hidden' === document.body.style.overflowY) {
    document.body.style.overflowY = 'visible'
  } else {
    document.body.style.overflowY = 'hidden'
  }
}
export function mobileMenuToggler() {
  const menu = document.querySelector('.js-menu')
  const open = document.querySelector('.js-toggler')
  const close = document.querySelector('.js-close')
  if (menu) {
    open.onclick = () => {
      menu.classList.add('is-active')
      open.classList.add('is-active')
      checkSidebarActivity()
    }
    close.onclick = () => {
      menu.classList.remove('is-active')
      open.classList.remove('is-active')
      checkSidebarActivity()
    }
  }
}

<template lang="pug">
  include ./../../../mixins
  div.position--relative
    preloader(v-if="isLoad" :is-white="true")
    row.ma--bottom_3lg(space="md")
      cell(cols="12 3-lg")
        row(justify="end")
          cell
            bem-button.ma--top_sm(
              block="link"
              variant="underline"
              color="accent-2"
              size="3xs"
              @event="clearFilters()"
            ) {{_('See all catalogs')}}
        filter-section.ma--bottom_sm(
          :filter-data="filterData"
          :params="paramsObj"
          @update-params="paramsObj = $event; requestForCards()"
        )
      cell(cols="12 9-lg")
        //-cards
        row.ma--bottom_3lg(v-if="!isResponse" space="sm-xxl")
          slot
        renderer.ma--bottom_3lg(
          class-render='g-row g-row--space_sm-xxl'
          v-if="isResponse"
          :result="response"
        )
        bem-text(
          v-if="isResponse && response.length === 0"
          appearance="title"
          tag="p"
          size="3sm"
          align="center"
        ) {{_('There are no results')}}
        row(justify="center")
          cell(cols="12 9-lg 6-xxl")
            pagination(
              v-if="count > perPage"
              class-wrapper="pagination__wrapper--light"
              :is-scroll="false"
              :current='page'
              :total='count'
              :perPage='perPage'
              @page-changed="page = $event, requestForCards($event)"
            )
</template>

<script>
import Preloader from '@/js/components/UiElements/Preloader'
import {
  standFilterResource,
  standFilterEmptyResource,
  standCardsResource,
  standCardsEmptyResource,
} from '@/js/services/stand'
import { prefixLanguage } from '@/js/helpers/url'
import FilterSection from './FilterSection'
import CatalogMixin from './catalog-mixin'

const one = 1

export default {
  name: 'stand-catalog',
  mixins: [
    CatalogMixin,
  ],
  components: {
    FilterSection,
    Preloader,
  },
  props: {
    slug: String,
    pagination: Object,
  },
  data() {
    return {
      tracked: false,
      favorite: false,
      isProcessingClear: false,
      paramsObj: {},
      filterData: [],
      isResponse: false,
      response: '',
      isLoad: false,
      count: this.pagination.count,
      page: this.pagination.page,
      perPage: this.pagination.perPage,
      favoriteDisabled: true,
      trackedDisabled: true,
      isShowCheckbox: window.is_auth,
    }
  },
  created() {
    window.onpopstate = async () => {
      window.location.reload()
    }
  },
  mounted() {
    this.checkUrl(this.slug)
    this.getFilters()
  },
  methods: {
    getFilters() {
      return standFilterResource.execute({
        page: 1,
        slug: this.slug,
      })
        .then(res => {
          this.filterData = this.formatFilters(res.data.items)
        })
    },
    requestForCards(page = one) {
      this.addCheckboxValue()
      let filterResource = standFilterResource
      let cardsResource = standCardsResource
      if (0 === Object.keys(this.paramsObj).length) {
        filterResource = standFilterEmptyResource
        cardsResource = standCardsEmptyResource
      }
      this.isLoad = true
      Promise.all([
        filterResource.execute({
          slug: this.slug,
          filters: this.paramsObj,
          page,
        }),
        cardsResource.execute({
          slug: this.slug,
          filters: this.paramsObj,
          page,
        }),
      ])
        .then(res => {
          this.handleFilters(res[0])
          this.handleCards(res[1])
          this.pushHistory()
          this.isProcessingClear = false
          this.isLoad = false
        })
    },
    handleFilters(res) {
      this.filterData = this.formatFilters(res.data.items)
      this.page = 1
    },
    async clearFilters() {
      this.isProcessingClear = true
      this.paramsObj = {}
      this.requestForCards()
      this.favorite = false
      this.tracked = false
    },
    pushHistory() {
      const baseUrl = `${prefixLanguage(window.location.origin)}/stand/${this.slug}/`
      let page
      if (1 < this.page) {
        page = `page/${this.page}/`
      } else {
        page = ''
      }
      // because we use tabs with hash
      const hash = window.location.hash || ''
      window.history.pushState(null, null, `${baseUrl}${this.generateUrl(this.paramsObj)}${page}${hash}`)
    },
    addCheckboxValue() {
      if (true === this.favorite) this.paramsObj.favorite = [this.favorite]
      else delete this.paramsObj.favorite
      if (true === this.tracked) this.paramsObj.tracked = [this.tracked]
      else delete this.paramsObj.tracked
    },
  },
}
</script>

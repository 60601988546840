import {
  postResource,
  createResource,
} from '@/js/resource/resource'
import PATH from './path'

export const favoriteResource = createResource(
  PATH.ACTIONS.FAVORITE, postResource
)

export const addCompareResource = createResource(
  PATH.ACTIONS.ADD_COMPARE, postResource
)

export const deleteCategoryResource = createResource(
  PATH.ACTIONS.DELETE_CATEGORY, postResource
)

export const deleteProductResource = createResource(
  PATH.ACTIONS.DELETE_PRODUCT, postResource
)

export const trackProductResource = createResource(
  PATH.ACTIONS.TRACK_PRODUCT, postResource
)

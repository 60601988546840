import ModalHandler from './ModalHandler'
import CommonModal from './CommonModal'
import VideoModal from './VideoModal'
import RequestModal from './RequestModal'
import FeatureModal from './FeatureModal'

export default function install(Vue) {
  Vue.component(ModalHandler.name, ModalHandler)
  Vue.component(CommonModal.name, CommonModal)
  Vue.component(VideoModal.name, VideoModal)
  Vue.component(RequestModal.name, RequestModal)
  Vue.component(FeatureModal.name, FeatureModal)
}

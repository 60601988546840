export default {
  bind(elem) {
    const tabs = Array.from(elem.getElementsByClassName('js-tab'))
    const tabContent = Array.from(elem.getElementsByClassName('js-tab-content'))
    if (tabs.length) {
      tabs.forEach((el, i) => {
        el.addEventListener('click', () => {
          tabs.forEach(tab => tab.classList.remove('is-active'))
          el.classList.add('is-active')
          tabContent.forEach(tabC => tabC.classList.remove('is-active'))
          tabContent[i].classList.add('is-active')
          el.scrollIntoView({ block: 'start', behavior: 'smooth' })
          const tips = Array.from(document.getElementsByClassName('vue-tooltip'))
          if (tips) {
            tips.forEach(tip => {
              tip.classList.remove('vue-tooltip-visible')
              tip.classList.add('vue-tooltip-hidden')
            })
          }
        }, { passive: true })
      })
    }
  },
}

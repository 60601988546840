import {
  postResource,
  createResource,
  getResource,
  patchResource,
} from '@/js/resource/resource'
import PATH from './path'

export const createCatalogResource = createResource(
  PATH.CABINET.CREATE_CATALOG, postResource
)

export const createProductResource = createResource(
  PATH.CABINET.CREATE_PRODUCT, postResource
)

export const productListResource = createResource(
  PATH.CABINET.PRODUCT_LIST, getResource
)

export const productDetailResource = createResource(
  PATH.CABINET.PRODUCT_DETAIL, getResource
)

export const productDeleteResource = createResource(
  PATH.CABINET.PRODUCT_DELETE, postResource
)

export const productUpdateResource = createResource(
  PATH.CABINET.PRODUCT_UPDATE, patchResource
)

export const productVisibilityResource = createResource(
  PATH.CABINET.PRODUCT_VISIBILITY, postResource
)

export const favoriteListResource = createResource(
  PATH.CABINET.FAVORITE_LIST, getResource
)

export const productCategoriesResource = createResource(
  PATH.CABINET.PRODUCT_CATEGORY, getResource
)

export const productSubategoriesResource = createResource(
  PATH.CABINET.PRODUCT_SUBCATEGORY, postResource
)

<template lang="pug">
  include ./../../../mixins
  +b.action-cabinet-btn.ma--left_sm.BUTTON(@click.prevent="$emit('event', $event)")
    bem-icon(
      type="close"
      color="accent-2"
      size="2xs"
    )
</template>

<script>
export default {
  name: 'action-compoment',
}
</script>

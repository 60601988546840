export default function toggleHandler() {
  const tabs = Array.from(document.getElementsByClassName('js-toggle'))
  const tabContent = Array.from(document.getElementsByClassName('js-toggle-content'))
  if (tabs.length) {
    tabs.forEach((el, i) => {
      el.addEventListener('click', () => {
        if (tabContent[i].classList.contains('is-active')) {
          tabContent[i].classList.remove('is-active')
        } else {
          tabContent.forEach(tabC => tabC.classList.remove('is-active'))
          tabContent[i].classList.add('is-active')
        }
        tabContent[i].scrollIntoView({ block: 'start', behavior: 'smooth' })
      }, { passive: true })
    })
  }
}

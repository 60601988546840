import {
  CUSTOMER_BASE_PATH,
  CUSTOMER_DASHBOARD_PATH,
  CUSTOMER_SETTINGS_PATH,
  CUSTOMER_FINANCE_PATH,
  CUSTOMER_PRODUCT_REQUEST_PATH,
  CUSTOMER_PRODUCT_REQUEST_SENT_PATH,
  CUSTOMER_PRODUCT_REQUEST_GOT_PATH,
  CUSTOMER_REQUEST_VIEW,
  CUSTOMER_CATALOG_PATH,
  CUSTOMER_PRODUCTS_PATH,
  CUSTOMER_PRODUCT_CREATE_PATH,
  CUSTOMER_SUBSCRIPTIONS_PATH,
  CUSTOMER_FAVORITE_PATH,
  CUSTOMER_TRACKED_PATH,
  CUSTOMER_FINANCE_AVAILABLE_PATH,
  CUSTOMER_FINANCE_REQUESTS_PATH,
  CUSTOMER_PERMISSION,
} from '@/js/components/path'

import {
  createProductResource,
} from '@/js/services/cabinet'
import {
  requestSentListResource,
  requestGotListResource,
} from '@/js/services/request'
import { checkNoShop } from '@/js/router/security'

export const routes = [
  {
    path: CUSTOMER_BASE_PATH,
    redirect: () => CUSTOMER_DASHBOARD_PATH,
    component: () => import('@/js/components/Cabinet/CabinetOrigin'),
    meta: {
      userRole: 'client',
    },
    children: [
      {
        path: CUSTOMER_DASHBOARD_PATH,
        component: () => import('@/js/components/Cabinet/Common/CabinetDashboard'),
        name: 'customer-dashboard',
      },
      {
        path: CUSTOMER_SETTINGS_PATH,
        component: () => import('@/js/components/Cabinet/Common/CabinetSettings'),
        name: 'customer-settings',
      },
      {
        path: CUSTOMER_FINANCE_PATH,
        redirect: () => CUSTOMER_FINANCE_AVAILABLE_PATH,
        component: () => import('@/js/components/Cabinet/Common/CabinetFinance'),
        name: 'customer-finance',
        meta: { requiresShop: true },
        children: [
          {
            path: CUSTOMER_FINANCE_AVAILABLE_PATH,
            component: () => import('@/js/components/Cabinet/Table/AvailableServicesTable'),
            name: 'customer-available-services',
            meta: { requiresShop: true },
          },
          {
            path: CUSTOMER_FINANCE_REQUESTS_PATH,
            component: () => import('@/js/components/Cabinet/Table/ServicesRequestTable'),
            name: 'customer-requests-services',
          },
        ],
      },
      {
        path: CUSTOMER_PRODUCT_REQUEST_PATH,
        redirect: () => CUSTOMER_PRODUCT_REQUEST_SENT_PATH,
        component: () => import('@/js/components/Cabinet/Common/CabinetRequest'),
        name: 'customer-request',
        children: [
          {
            path: CUSTOMER_PRODUCT_REQUEST_SENT_PATH,
            component: () => import('@/js/components/Cabinet/Common/RequestWrapper'),
            name: 'customer-request-sent',
            props: {
              resource: requestSentListResource,
              isSent: true,
            },
          },
          {
            path: CUSTOMER_PRODUCT_REQUEST_GOT_PATH,
            component: () => import('@/js/components/Cabinet/Common/RequestWrapper'),
            name: 'customer-request-got',
            props: {
              resource: requestGotListResource,
              isSent: false,
            },
          },
        ],
      },
      {
        path: CUSTOMER_REQUEST_VIEW,
        component: () => import('@/js/components/Cabinet/Common/CabinetRequestView'),
        name: 'customer-request-view',
      },
      {
        path: CUSTOMER_PRODUCTS_PATH,
        component: () => import('@/js/components/Cabinet/Common/CabinetProducts'),
        name: 'customer-products',
      },
      {
        path: CUSTOMER_PRODUCT_CREATE_PATH,
        component: () => import('@/js/components/Cabinet/Common/CabinetProductCreate'),
        name: 'customer-product-create',
        meta: { requiresShop: true },
        props: {
          createResource: createProductResource,
          productListPath: 'customer-products',
        },
        beforeEnter(to, from, next) {
          checkNoShop(to, from, next)
        },
      },
      {
        path: CUSTOMER_CATALOG_PATH,
        component: () => import('@/js/components/Cabinet/Common/CabinetCatalog'),
        name: 'customer-catalog',
      },
      {
        path: CUSTOMER_FAVORITE_PATH,
        component: () => import('@/js/components/Cabinet/Common/CabinetFavorites'),
        name: 'customer-favorites',
        meta: { requiresShop: true },
      },
      {
        path: CUSTOMER_SUBSCRIPTIONS_PATH,
        component: () => import('@/js/components/Cabinet/Common/CabinetSubscriptions'),
        name: 'customer-subscriptions',
      },
      {
        path: CUSTOMER_TRACKED_PATH,
        component: () => import('@/js/components/Cabinet/Common/CabinetTracked'),
        name: 'customer-tracked',
      },
      {
        path: '*',
        component: () => import('@/js/components/Cabinet/NotFound'),
        name: 'not-found',
      },
      {
        path: CUSTOMER_PERMISSION,
        component: () => import('@/js/components/Cabinet/NoPermission'),
        name: 'no-permission',
      },
    ],
  },
]

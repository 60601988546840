<template lang="pug">
  include ./../../../mixins
  div
    +b.icon--type_close.--size_xs.modal-close--common.BUTTON(
      tabindex="-1"
      @click="$emit('close')"
    )
    bem-text.ma--bottom_2sm(
      tag="h2"
      appearance="title"
      color="accent-1"
      variant="bold"
      height="sm"
      size="md"
    ) {{_('Specify the number of days for which you want to activate the services')}}
    validation-observer(
      tag="form"
      ref="observer"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit"
    )
      d-control-pure-input.position--relative.ma--bottom_2sm(
        rules="required|numeric"
        type="number"
        :placeholder="_('Date')"
        v-model="formdata.countDays"
        class-name="form__input"
        :required="false"
        name="countDays"
        @input="toggleCalcBtn()"
      )
      +b.error-text.ma--bottom_sm.--top_sm.P(
        v-for="error in fieldErrors"
        v-if="showErrors === true"
      ) {{ error }}
      bem-button(
        :disabled="disableCalcBtn === true"
        text="title"
        :variant="['fill-accent-1', 'bold', 'uppercase']"
        border="xs"
        space="sm"
        width="full"
        @event="validateBeforeSubmit()"
      ) {{_('CALCULATE')}}
    div(v-if="showTable === true")
      +b.finance-table.ma--top_sm.ma--bottom_sm.TABLE
        tbody
          +e.row.TR(v-for="row in calculatedTable.services")
            +e.data.TD
              bem-text(
                tag="p"
                color="accent-1"
                size="3xs"
              ) {{ row.title }}
            +e.data.TD
              bem-text(
                tag="p"
                color="accent-1"
                size="3xs"
              ) $ {{ row.price }}
        +e.footer.TFOOT
          +e.data.TD
            bem-text(
              tag="p"
              color="accent-1"
              size="3xs"
              variant="bold"
            ) Total
          +e.data.TD
            bem-text(
              tag="p"
              color="accent-1"
              size="3xs"
              variant="bold"
            ) $ {{ calculatedTable.total }}
      bem-button(
        :disabled="disableSendBtn == true"
        text="title"
        :variant="['fill-accent-1', 'bold', 'uppercase']"
        border="xs"
        space="sm"
        width="full"
        @event="sendRequest()"
      ) {{_('ORDER SERVICES')}}
</template>

<script>
import { createFeatureResource, featureCalculateResource } from '@/js/services/features'
import FormMixin from './form-mixin'

export default {
  name: 'finance-form',
  mixins: [FormMixin],
  props: {
    objectItem: Object,
  },
  data() {
    return {
      formdata: {
        idsFeatures: this.objectItem.id,
        countDays: null,
      },
      showTable: false,
      disableCalcBtn: false,
      calculatedTable: {},
      disableSendBtn: false,
      showErrors: false,
    }
  },
  methods: {
    sendRequest() {
      return createFeatureResource.execute({}, this.formdata)
        .then(res => {
          // this.$emit('action')
          this.handleResponse({ modal: true, res })
        })
    },
    send(data) {
      return featureCalculateResource.execute({}, data)
        .then(res => {
          this.calculatedTable = res.data.item
          this.showTable = true
          this.disableCalcBtn = true
          this.disableSendBtn = false
          this.showErrors = false
        })
    },
    toggleCalcBtn() {
      if (true === this.showTable) {
        this.disableCalcBtn = true
        this.disableSendBtn = true
      }
      if ('' !== this.formdata) {
        this.disableCalcBtn = false
      }
    },
    updateValidator(errors) {
      this.showErrors = true
      // console.log("🚀 ~ file: FinanceForm.vue ~ line 135 ~ updateValidator ~ errors", errors)
      // const parsed = defaultValidatorErrorsParser(errors)
      // this.$refs.observer.setErrors(parsed)
      this.fieldErrors = [errors.nonFieldErrors.message]
    },
  },
}
</script>

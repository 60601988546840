import {
  getResource,
  createResource,
  postResource,
  putResource,
} from '@/js/resource/resource'
import PATH from './path'

export const managerNotificationResource = createResource(
  PATH.MANAGER.MANAGER_NOTIFICATION, getResource
)

export const managerUserListResource = createResource(
  PATH.MANAGER.USER_LIST, getResource
)

export const managerUserDetailResource = createResource(
  PATH.MANAGER.USER_DETAIL, postResource
)

export const managerUserUpdateResource = createResource(
  PATH.MANAGER.USER_UPDATE, postResource
)

export const managerStoreDetailResource = createResource(
  PATH.MANAGER.STORE_DETAIL, postResource
)

export const managerStoreUpdateResource = createResource(
  PATH.MANAGER.STORE_UPDATE, putResource
)

export const managerStoreCreateResource = createResource(
  PATH.MANAGER.STORE_CREATE, postResource
)

export const managerProductListResource = createResource(
  PATH.MANAGER.PRODUCT_LIST, getResource
)

export const managerProductCategoriesResource = createResource(
  PATH.MANAGER.PRODUCT_CATEGORIES, getResource
)

export const managerProductSubcategoriesResource = createResource(
  PATH.MANAGER.PRODUCT_SUBCATEGORIES, postResource
)

export const managerProductCreateResource = createResource(
  PATH.MANAGER.PRODUCT_CREATE, postResource
)

export const managerServicesListResource = createResource(
  PATH.MANAGER.SERVICES_LIST, getResource
)

export const managerServicesFreeResource = createResource(
  PATH.MANAGER.SERVICE_FREE, postResource
)

export const managerQuestionsResource = createResource(
  PATH.MANAGER.MANAGER_QUESTIONS, getResource
)

export const managerQuestionsDetailResource = createResource(
  PATH.MANAGER.DETAIL_QUESTIONS, getResource
)

export const managerSetQuestionsResource = createResource(
  PATH.MANAGER.SET_QUESTIONS, postResource
)

<template lang="pug">
  include ./../../../mixins
  div
    +b.icon--type_close.--size_xs.modal-close--common.BUTTON(tabindex="-1" @click="$emit('close')")
    bem-text.ma--bottom_2md(
      tag="h2"
      appearance="title"
      color="accent-1"
      size="2md"
      :variant="['bold', 'uppercase']"
    ) {{_('Product request')}}
    validation-observer(
      tag="form"
      ref="observer"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit"
    )
      +b.form.ma--bottom_sm
        d-control-input(
          rules="alpha_spaces|required"
          type="text"
          :placeholder="_('Name')"
          v-model="formdata.name"
          class-name="form__input"
          label-class="form__label"
          :input-label="_('Name')"
          :required="true"
          name="name"
        )
      +b.form.ma--bottom_sm
        d-control-input(
          rules="international_phone"
          v-mask="'+## ### ### ## ##'"
          type="tel"
          inputmode="tel"
          :placeholder="_('Phone')"
          v-model="formdata.phone"
          class-name="form__input"
          label-class="form__label"
          :input-label="_('Phone number')"
          :required="true"
          name="phone"
        )
      +b.form.ma--bottom_sm
        d-control-input(
          rules="email|required"
          type="email"
          inputmode="email"
          :placeholder="_('Email')"
          v-model="formdata.email"
          class-name="form__input"
          label-class="form__label"
          :input-label="_('E-mail')"
          :required="true"
          name="email"
        )
      +b.form.ma--bottom_sm
        d-control-input(
          type="text"
          v-model="formdata.subject"
          class-name="form__input"
          label-class="form__label"
          :input-label="_('Write your subject of your question')"
          :required="false"
          name="subject"
        )
      +b.form.ma--bottom_sm
        d-control-textarea(
          rules="required"
          type="textarea"
          v-model="formdata.question"
          class-name="form__textarea"
          label-class="form__label"
          :input-label="_('Your message')"
          :required="true"
          name="question"
        )
      bem-button(
        text="title"
        :variant="['fill-accent-1', 'bold']"
        border="xs"
        space="sm"
        width="full"
        @event="validateBeforeSubmit();sendTag()"
        :disable="isLoad"
      ) {{_('SEND')}}
</template>

<script>
import { mask } from 'vue-the-mask'
import { mapActions, mapGetters } from 'vuex'
import { requestAddResource } from '@/js/services/request'
import FormMixin from './form-mixin'

export default {
  name: 'request-form',
  mixins: [
    FormMixin,
  ],
  props: {
    objectItem: Object,
  },
  directives: { mask },
  data() {
    return {
      formdata: {
        name: '',
        phone: '',
        email: '',
        subject: '',
        question: '',
        shopLabel: this.objectItem.shopLabel,
        queryType: this.objectItem.queryType,
        idProduct: this.objectItem.productName,
      },
      subjValue: {},
    }
  },
  async mounted() {
    await this.getUserRequest()
    this.formdata.name = this.user.firstName
    this.formdata.phone = this.user.phone
    this.formdata.email = this.user.email
  },
  methods: {
    send(data) {
      this.isLoad = true
      return requestAddResource.execute({}, data)
        .then(res => {
          this.handleResponse({ modal: true, res })
          this.isLoad = false
        })
    },
    sendTag() {
      /* eslint-disable no-undef */
      gtag('event', 'click', { event_category: 'send_request' })
      /* eslint-enable no-undef */
    },
    ...mapActions('customerCabinet', [
      'getUserRequest',
    ]),
  },
  computed: {
    ...mapGetters('customerCabinet', {
      user: 'getUserInfo',
    }),
  },
}
</script>

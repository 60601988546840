<template lang="pug">
  include ./../../../mixins
  +b.form.ma--bottom_sm-till-lg
    d-control-pure-input(
      type="search"
      :placeholder="_('Search')"
      v-model="val"
      class-name="form__input"
      inputmode="search"
      @keyup.enter="handleSearch()"
      @clear="clearSearch()"
    )
      //- @input="handleSearch()"
</template>

<script>
export default {
  name: 'filter-search',
  props: {
    params: Object,
  },
  data() {
    return {
      val: '',
      obj: this.params,
    }
  },
  watch: {
    params: {
      handler(nval) {
        if (nval.search) {
          this.obj = nval
          this.normalizeSearch(nval.search[0])
        }
      },
      deep: true,
    },
  },
  methods: {
    handleSearch() {
      this.obj.search = this.val
      if (undefined === this.obj.search[0]) {
        delete this.obj.search
      }
      this.$emit('search', this.obj)
    },
    normalizeSearch() {
      this.val = decodeURIComponent(this.params?.search) || ''
      // if (value.includes('%20')) this.val = value.replace('%20', ' ')
      // else this.val = decodeURIComponent(this.params?.search) || ''
    },
    clearSearch() {
      this.val = ''
      delete this.obj.search
      this.$emit('search', this.obj)
    },
  },
}
</script>

<template lang="pug">
  include ./../../../mixins
  div
    +b.action-btn(v-if="isAuth")
      +e.wrapper
        +e.button.icon--type_compare.--color_accent-2.A(:href="link" aria-label="compare")
        +e.counter {{ countItem }}
    modal-handler(
      v-else
      component-name="registration-form"
      modal-class="modal modal--size_sm modal--container modal--padding_md"
      :object-item="objectItem"
    )
      template(#default="{ handler }")
        +b.action-btn
          +e.wrapper
            +e.button.icon--type_compare.--color_accent-2.BUTTON(@click="handler" aria-label="compare")
</template>

<script>
import { mapGetters } from 'vuex'
import { djangoToJsBoolean } from '@/js/helpers/utils'

export default {
  name: 'compare-button',
  props: {
    count: String,
    objectItem: Object,
    link: String,
  },
  data() {
    return {
      isAuth: djangoToJsBoolean(window.is_auth),
      countItem: this.count,
    }
  },
  watch: {
    'getActions.compare': {
      handler(val) {
        this.countItem = val
      },
    },
  },
  computed: {
    ...mapGetters('common', [
      'getActions',
    ]),
  },
}
</script>

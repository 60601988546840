import {
  postResource,
  createResource,
  getResource,
  patchResource,
} from '@/js/resource/resource'
import PATH from './path'

export const settingsUserDetailResource = createResource(
  PATH.CABINET_SETTINGS.SETTINGS_USER_DETAIL, getResource
)

export const settingsShopDetailResource = createResource(
  PATH.CABINET_SETTINGS.SETTINGS_SHOP_DETAIL, getResource
)

export const settingsUserUpdateResource = createResource(
  PATH.CABINET_SETTINGS.SETTINGS_USER_UPDATE, patchResource
)

export const settingsShopUpdateResource = createResource(
  PATH.CABINET_SETTINGS.SETTINGS_SHOP_UPDATE, patchResource
)

export const settingsShopCreateResource = createResource(
  PATH.CABINET_SETTINGS.SETTINGS_USER_CREATE, postResource
)

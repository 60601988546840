import UiDropdown from './UiDropdown'
import UiHovered from './UiHovered'
import UiClickOutside from './UiClickOutside'
import UiSocial from './UiSocial'
import Pagination from './Pagination'
import VideoWrapper from './VideoWrapper'
import ProductOptions from './ProductOptions'
import LanguageSwitch from './LanguageSwitch'

const UiCaptcha = () => import('./UiCaptcha')
const UiSlider = () => import('./UiSlider')
const UiLightbox = () => import('./UiLightbox')
const UiCircleRating = () => import('./UiCircleRating')

export default function install(Vue) {
  Vue.component(UiDropdown.name, UiDropdown)
  Vue.component(UiHovered.name, UiHovered)
  Vue.component(UiClickOutside.name, UiClickOutside)
  Vue.component(UiSocial.name, UiSocial)
  Vue.component(Pagination.name, Pagination)
  Vue.component(VideoWrapper.name, VideoWrapper)
  Vue.component(ProductOptions.name, ProductOptions)
  Vue.component(LanguageSwitch.name, LanguageSwitch)
  Vue.component('UiCaptcha', UiCaptcha)
  Vue.component('UiSlider', UiSlider)
  Vue.component('UiCircleRating', UiCircleRating)
  Vue.component('UiLightbox', UiLightbox)
}

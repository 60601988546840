<template lang="pug">
  include ./../../../mixins
  +b.g-row
    +b.language.A(
      v-for="lang in languages"
      :class="{'is-active': lang.value == language}"
      @click.prevent="changeLanguage(lang.value)"
    ) {{ lang.label }}
</template>

<script>
export default {
  name: 'language-switch',
  data() {
    return {
      language: '',
      languages: [
        { value: 'en', label: this._('EN') },
        { value: 'ru', label: this._('RU') },
      ],
    }
  },
  mounted() {
    const defaultLang = 'en'
    const path = window.location.pathname
    const lang = path.split('/')[1]
    if (2 >= lang.length && lang !== defaultLang) {
      this.language = lang || defaultLang
    } else if (2 <= lang.length) {
      this.language = defaultLang
    } else if ('/' === path) {
      this.language = defaultLang
    }
  },
  methods: {
    changeLanguage(language) {
      if (2 === window.location.pathname.split('/')[1].length) {
        const replaced = window.location.pathname.replace('ru/', '')
        window.location.replace(`${window.location.origin}${replaced}`)
      } else {
        window.location.replace(`${window.location.origin}/${language}${window.location.pathname}`)
      }
    },
  },
}
</script>

<template lang='pug'>
  include ./../../../mixins
  +b.DIV
    +b.icon--type_close.--size_xs.modal-close--video.BUTTON(
      tabindex="-1"
      @click="$emit('close')"
    )
    +b.video
      +e.frame-wrapper
        +e.frame.IFRAME(
          :src='handleVideo(objectItem.src)'
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen height='100%'
        )
  </div>
</template>

<script>
export default {
  name: 'video-modal',
  props: {
    objectItem: {},
  },
  methods: {
    handleVideo(video) {
      const VIDEO_REGEXP = /https:\/\/(:?www.)?(\w*)/
      if ('youtube' === video.match(VIDEO_REGEXP)[2]) {
        return this.getDataYoutube(video)
      } if ('vimeo' === video.match(VIDEO_REGEXP)[2]) {
        return this.getDataVimeo(video)
      }
      return false
    },
    getDataVimeo(video) {
      const id = video.split('/')[3]
      return `https://player.vimeo.com/video/${id}`
    },
    getDataYoutube(video) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
      const match = video.match(regExp)
      const eleven = 11
      let id
      if (match && match[2].length === eleven) {
        id = [match[2]]
      }

      return `//www.youtube.com/embed/${id}?autoplay=1`
    },
  },
}
</script>

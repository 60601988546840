export function setTableWrap() {
  const parent = document.querySelector('.t-content')
  if (parent) {
    let tables = parent.querySelectorAll('table')
    let iframes = parent.querySelectorAll('iframe')
    tables = Array.from(tables)
    iframes = Array.from(iframes)
    const zero = 0
    if (zero < tables.length) {
      tables.forEach(el => {
        const wrapper = document.createElement('div')
        el.parentNode.insertBefore(wrapper, el)
        wrapper.appendChild(el)
        wrapper.classList.add('table-wrapper')
      })
    }
    if (zero < iframes.length) {
      iframes.forEach(el => {
        const wrapper = document.createElement('div')
        el.parentNode.insertBefore(wrapper, el)
        wrapper.appendChild(el)
        wrapper.classList.add('iframe-wrapper')
      })
    }
  }
}

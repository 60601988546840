import {
  postResource,
  createResource,
  getResource,
} from '@/js/resource/resource'
import PATH from './path'

export const createFeatureResource = createResource(
  PATH.FEATURES.FEATURE_CREATE, postResource
)

export const featuresListResource = createResource(
  PATH.FEATURES.FEATURE_LIST, getResource
)

export const featureCalculateResource = createResource(
  PATH.FEATURES.FEATURE_CALCLATE, postResource
)

export const featureCustomerResource = createResource(
  PATH.FEATURES.FEATURE_CUSTOMER_LIST, getResource
)

export const featureManagerListResource = createResource(
  PATH.FEATURES.FEATURE_MANAGER_LIST, getResource
)

export const featureManagerDetailResource = createResource(
  PATH.FEATURES.FEATURE_MANAGER_DETAIL, postResource
)

export const featureManagerUpdateResource = createResource(
  PATH.FEATURES.FEATURE_MANAGER_UPDATE, postResource
)

import { scrollUnlock, scrollLock } from '@/js/helpers/scroll'

export default {
  data() {
    return {
      isShowFilters: false,
    }
  },
  async mounted() {
    this.setScreenWidth()
    window.addEventListener('resize', () => {
      this.setScreenWidth()
    }, { passive: true })
  },
  methods: {
    setScreenWidth() {
      if (window.matchMedia('(min-width: 992px)').matches) {
        this.isShowFilters = false
      }
    },
    toggleFilter() {
      this.isShowFilters = !this.isShowFilters
      if (this.isShowFilters) {
        // document.body.style.overflowY = 'hidden'
        scrollLock()
      } else {
        // document.body.style.overflowY = 'visible'
        scrollUnlock()
      }
    },
    handleCards(res) {
      this.isResponse = true
      this.response = res.data
      // console.log("handleCards -> this.response", this.response)
      this.page = res.meta.page
      this.count = res.meta.count
      this.perPage = res.meta.perPage
    },
    generateUrl(params) {
      let url = ''
      Object.keys(params).forEach((key, i) => {
        if (0 === i) {
          url += `${key}=${'search' === key ? params[key] : this.createStringFromArray(params[key])}`
        } else {
          url += `;${key}=${'search' === key ? params[key] : this.createStringFromArray(params[key])}`
        }
      })
      if ('' !== url) {
        url = `${url}/`
      }
      return url
    },
    formatFilters(filters) {
      filters.forEach(el => {
        el.options.forEach(item => {
          item.$isDisabled = item.disable
        })
      })
      return filters
    },
    createStringFromArray(arr) {
      let params = ''
      arr.forEach((el, i) => {
        if (i !== arr.length - 1) {
          params += `${el},`
        } else {
          params += `${el}`
        }
      })
      return params
    },
    checkUrl(slug) {
      const base = slug
      const pathElem = window.location.pathname.split('/')
      const params = 'page' !== pathElem[pathElem.indexOf(base) + 1] ? pathElem[pathElem.indexOf(base) + 1] : ''
      if ('' !== params) {
        this.parseUrl(params)
      }
    },
    parseUrl(params) {
      const paramsString = params
      const paramsArray = paramsString.split(';')
      const obj = {}
      paramsArray.forEach(el => {
        obj[el.split('=')[0]] = el.split('=')[1].split(',')
        if ('true' === el.split('=')[1].split(',')) el.split('=')[1].split(',')
      })
      this.paramsObj = obj
    },
  },
}

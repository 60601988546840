<template lang="pug">
  include ./../../../mixins
  div
    +b.icon--type_close.--size_xs.modal-close--common.BUTTON(
      tabindex="-1"
      @click="$emit('close')"
    )
    bem-text.ma--bottom_2md(
      tag="h2"
      appearance="title"
      color="accent-1"
      size="2md"
      :variant="['bold', 'uppercase']"
    ) {{_('CHANGE PASSWORD')}}
    validation-observer(
      tag="form"
      ref="observer"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit"
    )
      +b.form.ma--bottom_sm
        d-control-input(
          rules="required|min:8"
          ref='password'
          type="password"
          v-model="formdata.oldPassword"
          class-name="form__input"
          label-class="form__label"
          :input-label="_('Enter old password')"
          :required="true"
          name="oldPassword"
        )
      +b.form.ma--bottom_sm
        d-control-input(
          rules="required|min:8"
          ref='password'
          type="password"
          v-model="formdata.newPassword1"
          class-name="form__input"
          label-class="form__label"
          :input-label="_('Enter new password')"
          :required="true"
          name="newPassword1"
        )
      +b.form.ma--bottom_sm
        d-control-input(
          rules="required|min:8|confirmed:newPassword1"
          type="password"
          v-model="formdata.newPassword2"
          class-name="form__input"
          label-class="form__label"
          :input-label="_('Enter the new password again')"
          :required="true"
          name="newPassword2"
        )
      +b.error-text.ma--bottom_sm.--top_sm.P(
        v-for="error in fieldErrors"
        v-if="fieldErrors.length > 0"
      ) {{ error }}
      bem-button(
        text="title"
        :variant="['fill-accent-1', 'bold']"
        border="xs"
        space="sm"
        width="full"
        @event="validateBeforeSubmit"
      ) {{_('SAVE')}}
</template>

<script>
import { changePasswordResource } from '@/js/services/auth'
import FormMixin from './form-mixin'

export default {
  name: 'change-password',
  mixins: [
    FormMixin,
  ],
  data() {
    return {
      formdata: {
        oldPassword: '',
        newPassword1: '',
        newPassword2: '',
      },
    }
  },
  methods: {
    send(data) {
      return changePasswordResource.execute({}, data)
        .then(res => {
          this.handleResponse({ modal: true, res })
        })
    },
  },
}
</script>

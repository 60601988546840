<template lang="pug">
  include ./../../../../mixins
  div
    +b.icon--type_close.--size_xs.modal-close--common.BUTTON(
      v-if="isModal"
      @click="$emit('close')"
    )
    bem-text.ma--bottom_2md(
      tag="h2"
      appearance="title"
      :variant="['bold', 'center']"
      size="md"
      color="accent-1"
      height="xs"
    ) {{_('You can use this feature through the finance section by paying for the service')}}
    bem-button(
      text="title"
      :variant="['fill-accent-1', 'bold', 'uppercase']"
      border="xs"
      space="3xs"
      width="full"
      @event=""
    ) {{_('Go to features')}}
</template>

<script>
export default {
  name: 'feature-warning',
  props: {
    isModal: {
      required: false,
      default: true,
    },
  },
}
</script>

// customer paths
export const CUSTOMER_BASE_PATH = '/customer/'
export const CUSTOMER_DASHBOARD_PATH = '/customer/dashboard/'
export const CUSTOMER_SETTINGS_PATH = '/customer/settings/'
export const CUSTOMER_FINANCE_PATH = '/customer/finance/'
export const CUSTOMER_FINANCE_AVAILABLE_PATH = '/customer/finance/available/'
export const CUSTOMER_FINANCE_REQUESTS_PATH = '/customer/finance/requests/:page?/'
export const CUSTOMER_PRODUCTS_PATH = '/customer/products/'
export const CUSTOMER_PRODUCT_CREATE_PATH = '/customer/products/item/:id?/'
export const CUSTOMER_PRODUCT_REQUEST_PATH = '/customer/product-request/'
export const CUSTOMER_PRODUCT_REQUEST_SENT_PATH = '/customer/product-request/sent/'
export const CUSTOMER_PRODUCT_REQUEST_GOT_PATH = '/customer/product-request/got/'
export const CUSTOMER_REQUEST_VIEW = '/customer/product-request/view/:id/'
export const CUSTOMER_CATALOG_PATH = '/customer/catalog/'
export const CUSTOMER_SUBSCRIPTIONS_PATH = '/customer/subscriptions/'
export const CUSTOMER_FAVORITE_PATH = '/customer/favorite/'
export const CUSTOMER_TRACKED_PATH = '/customer/tracked-products/'
export const CUSTOMER_PERMISSION = '/customer/403/'

// manager paths
export const MANAGER_BASE_PATH = '/manager/'
export const MANAGER_USERS_LIST_PATH = '/manager/user/'
export const MANAGER_USER_PATH = '/manager/users/view/:idUser/'
export const MANAGER_USER_GENERAL_PATH = '/manager/user/view/:idUser/general/'
export const MANAGER_USER_STORE_PATH = '/manager/user/view/:idUser/store/'
export const MANAGER_USER_PRODUCTS_PATH = '/manager/user/view/:idUser/products/'
export const MANAGER_USER_PRODUCT_ADD_PATH = '/manager/user/view/:idUser/products/item/:id?/'
export const MANAGER_USER_SERVICES_PATH = '/manager/user/view/:idUser/sevices/'
export const MANAGER_REQUESTS_LIST_PATH = '/manager/request/'
export const MANAGER_REQUESTS_VIEW_PATH = '/manager/request/view/:id/'
export const MANAGER_CONTACT_LIST_PATH = '/manager/contact-request/'
export const MANAGER_CONTACT_VIEW_PATH = '/manager/contact-request/view/:id/'
export const MANAGER_PERMISSION = '/manager/403/'

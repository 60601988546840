import {
  getResource,
  createResource,
} from '@/js/resource/resource'
import PATH from './path'

export const standFilterResource = createResource(
  PATH.STAND_PRODUCTS.STAND_FILTERS, getResource
)

export const standFilterEmptyResource = createResource(
  PATH.STAND_PRODUCTS.STAND_FILTERS_EMPTY, getResource
)

export const standCardsResource = createResource(
  PATH.STAND_PRODUCTS.STAND_LIST, getResource
)

export const standCardsEmptyResource = createResource(
  PATH.STAND_PRODUCTS.STAND_LIST_EMPTY, getResource
)

export const standsPageCardsResource = createResource(
  PATH.STAND_PRODUCTS.STANDS_PAGE_CARDS, getResource
)

export const standsPageCardsEmptyResource = createResource(
  PATH.STAND_PRODUCTS.STANDS_PAGE_CARDS_EMPTY, getResource
)

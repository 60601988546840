import {
  postResource,
  createResource,
  getResource,
} from '@/js/resource/resource'
import PATH from './path'

export const contactResource = createResource(
  PATH.COMMON.CONTACT, postResource
)

export const productOptionsResource = createResource(
  PATH.COMMON.PRODUCT_OPTIONS, getResource
)

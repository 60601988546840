import { requestNotificationsResource } from '@/js/services/request'
import { managerNotificationResource } from '@/js/services/manager'

export const common = {
  namespaced: true,
  state: {
    actionsCount: {
      compare: '',
      favorite: '',
      notifications: '',
    },
    managerNotification: {},
    notifications: {},
  },
  getters: {
    getActions: state => state.actionsCount,
    notificationsCount: state => state.notifications,
    managerNotificationsCount: state => state.managerNotification,
  },
  actions: {
    changeAction(context, payload) {
      context.commit('changeActionCount', payload)
    },
    getNotifications({ commit }) {
      return requestNotificationsResource.execute()
        .then(res => { commit('setNotifications', res.data.item) })
    },
    getManagerNotification({ commit }) {
      return managerNotificationResource.execute()
        .then(res => {
          commit('setManagerNotifications', res.data.item)
        })
    },
  },
  mutations: {
    changeActionCount(state, payload) {
      state.actionsCount[payload.name] = payload.count
    },
    setNotifications(state, payload) {
      state.notifications = payload
    },
    setManagerNotifications(state, payload) {
      state.managerNotification = payload
    },
  },
}

import {
  postResource,
  createResource,
  getResource,
  // patchResource,
} from '@/js/resource/resource'
import PATH from './path'

export const dynamicSearchResource = createResource(
  PATH.SEARCH.DYNAMIC_SEARCH, postResource
)

export const categoriesSearchResource = createResource(
  PATH.SEARCH.SEARCH_CATEGORIES, getResource
)

export const cardsSearchResource = createResource(
  PATH.SEARCH.CARD_LIST, getResource
)

<template lang="pug">
  include ./../../../mixins
  div
    +b.icon--type_close.--size_xs.modal-close--common.BUTTON(@click="$emit('close')")
    bem-text.ma--bottom_sm(
      appearance="title"
      tag="h3"
      color="accent-1"
      size="md"
      :variant="['center', 'uppercase', 'bold']"
    ) {{title}}
    bem-text.ma---bottom_sm(
      tag="p"
      color="main"
      size="sm"
      variant="center"
    ) {{text}}
</template>

<script>
export default {
  name: 'success-modal',
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
  },
}
</script>

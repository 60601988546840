import VueTrans, { compileMessages } from '@aspectus/vue-trans'

const dateTimeFormats = {}

export function createI18n({ Vue, I18n }) {
  Vue.use(VueTrans)

  const i18n = new I18n({
    ...compileMessages(),
    silentTranslationWarn: true,
    dateTimeFormats,
  })
  return i18n
}

export function setHeight() {
  const items = Array.from(document.querySelectorAll('.js-height'))
  const heights = items.map(item => item.clientHeight)
  function getMaxOfArray(numArray) {
    return Math.max.apply(null, numArray)
  }
  const heightVal = getMaxOfArray(heights)
  for (let i = 0; i < items.length; i++) {
    items[i].style.height = `${heightVal}px`
  }
}

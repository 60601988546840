<template lang="pug">
  include ./../../../mixins
  div
    validation-observer(
      tag="form"
      ref="observer"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit"
    )
      +b.form.ma--bottom_sm
        d-control-input(
          rules="required|min:8"
          ref='password'
          type="password"
          v-model="formdata.newPassword1"
          class-name="form__input"
          label-class="form__label"
          :input-label="_('Enter new password')"
          :required="true"
          name="newPassword1"
        )
      +b.form.ma--bottom_sm
        d-control-input(
          rules="required|min:8|confirmed:newPassword1"
          type="password"
          v-model="formdata.newPassword2"
          class-name="form__input"
          label-class="form__label"
          :input-label="_('Enter the new password again')"
          :required="true"
          name="newPassword2"
        )
      +b.error-text.ma--bottom_sm.--top_sm.P(
        v-for="error in fieldErrors"
        v-if="fieldErrors.length > 0"
      ) {{ error }}
      bem-button.ma--top_sm(
        text="title"
        :variant="['fill-accent-1', 'bold', 'uppercase']"
        border="xs"
        space="sm"
        width="full"
        @event="validateBeforeSubmit"
      ) {{_('Save')}}
</template>

<script>
import { newPasswordResource } from '@/js/services/auth'
import FormMixin from './form-mixin'

export default {
  name: 'new-password-form',
  props: {
    uid: String,
    token: String,
  },
  mixins: [
    FormMixin,
  ],
  data() {
    return {
      formdata: {
        uid: this.uid,
        token: this.token,
        newPassword1: '',
        newPassword2: '',
      },
    }
  },
  methods: {
    send(data) {
      return newPasswordResource.execute({}, data)
        .then(res => {
          this.handleResponse({ modal: false, res, redirect: true })
        })
    },
  },
}
</script>

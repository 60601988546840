<template lang="pug">
  include ./../../../mixins
  div
    template(v-if="isMobile")
      +b.search-btn.icon--type_search.--size_3xs.--color_white.BUTTON(
        @click="toggleSearch"
        aria-label="search"
      )
      +b.search(v-if="isShowSearch")
        +e.wrapper
          +e.position--left
            +e.close.icon--type_arrow-left.--color_accent-1.--size_sm.BUTTON(@click="toggleSearch")
          +e.input--mobile.INPUT(
            type="text"
            v-model="searchValue"
            @keyup="handleSearch"
            :placeholder="_('Enter your query')"
          )
          +e.position--right
            +b.search-btn.icon--type_search.--size_3xs.--color_white.BUTTON(@click="redirect()" aria-label="search")
          search-result(
            v-if="Object.keys(result).length"
            :result="result"
            :search="searchValue"
          )
    template(v-else)
      +b.search(:class="{'is-active': isActive}")
        +e.wrapper
          +e.position--left-desktop
            +b.icon--type_search.--size_3xs.--color_accent-1(
              v-if="isActive"
              aria-label="search"
            )
          +e.input--desktop.INPUT(
            ref="search"
            type="text"
            @keyup="handleSearch"
            v-model="searchValue"
            :placeholder="_('Search')"
            @focus="toggleSearchModal"
            @keyup.enter="redirect()"
          )
          +e.position--right-desktop
            +b.icon--type_search.--size_3xs.--color_accent-1(
              v-if="!isActive"
              aria-label="search"
            )
            +b.icon--type_close.--size_3xs.link--color_accent-1.pointer.BUTTON(
              v-else
              aria-label="close"
              @click="toggleSearchModal"
            )
          search-result(
            v-if="Object.keys(result).length && isActive"
            :result="result"
            :search="searchValue"
          )
</template>

<script>
import { dynamicSearchResource } from '@/js/services/search'

export default {
  name: 'header-search',
  components: {
    'search-result': () => import('./SearchResult.vue'),
  },
  props: {
    isMobile: Boolean,
    link: String,
  },
  data() {
    return {
      isShowSearch: false,
      isActive: false,
      result: {},
      searchValue: '',
      path: '',
    }
  },
  methods: {
    toggleSearch() {
      this.isShowSearch = !this.isShowSearch
    },
    toggleSearchModal() {
      this.isActive = !this.isActive
      this.checkModalActivity()
    },
    checkModalActivity() {
      const mask = document.querySelector('.js-mask')
      if (this.isActive) {
        mask.classList.add('is-active')
      } else {
        this.$refs.search.blur()
        mask.classList.remove('is-active')
      }
    },
    handleSearch() {
      if (2 < this.searchValue.length) {
        this.send()
      }
    },
    send() {
      return dynamicSearchResource.execute({}, { search: this.searchValue })
        .then(res => {
          this.result = res.data.item
        })
    },
    redirect() {
      this.path = `${this.link}search=${this.searchValue}/`
      window.location.replace(this.path)
    },
  },
}
</script>

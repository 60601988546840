import {
  postResource,
  createResource,
} from '@/js/resource/resource'
import PATH from './path'

export const registrationResource = createResource(
  PATH.AUTH.REGISTRATION, postResource
)

export const authResource = createResource(
  PATH.AUTH.AUTH, postResource
)

export const logoutResource = createResource(
  PATH.AUTH.LOGOUT, postResource
)

export const resetPasswordResource = createResource(
  PATH.AUTH.RESET, postResource
)

export const newPasswordResource = createResource(
  PATH.AUTH.NEW_PASSWORD, postResource
)

export const changePasswordResource = createResource(
  PATH.AUTH.CHANGE_PASSWORD, postResource
)

export const emailNotConfirmedResource = createResource(
  PATH.AUTH.EMAIL_NOT_CONFIRMED, postResource
)

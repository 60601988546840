export default function tabsHandler() {
  const tabs = Array.from(document.getElementsByClassName('js-tab'))
  const tabContent = Array.from(document.getElementsByClassName('js-tab-content'))
  if (tabs.length) {
    tabs.forEach((el, i) => {
      el.addEventListener('click', () => {
        window.history.pushState('', '', el.id);
        tabs.forEach(tab => tab.classList.remove('is-active'))
        el.classList.add('is-active')
        tabContent.forEach(tabC => tabC.classList.remove('is-active'))
        tabContent[i].classList.add('is-active')
        el.scrollIntoView({ block: 'start', behavior: 'smooth' })
      }, { passive: true })
    })
  }
  if (window.location.hash) {
    tabs.forEach((el, i) => {
      el.classList.remove('is-active')
      tabContent[i].classList.remove('is-active')
      if (window.location.hash === el.id) {
        el.classList.add('is-active')
        tabContent[i].classList.add('is-active')
      }
    })
  }
}

<template lang="pug">
  include ./../../../mixins
  div
    bem-text.ma--bottom_xs.ma--top_sm(
      tag="p"
      color="accent-1"
      variant="bold"
      size="3xs"
    ) {{ select.title }}
    multiselect(
      :options="select.options"
      :searchable="select.searchable"
      :multiple="select.multiple"
      :taggable="select.multiple"
      track-by="label"
      @input="emit(select.label)"
      v-model="option.value"
    )
    //- d-control-multiselect.ma--bottom_xs.ma--top_sm(
    //-   label-class="form__label"
    //-   :input-label="select.title"
    //-   :options="select.options"
    //-   :searchable="select.searchable"
    //-   :multiple="select.multiple"
    //-   :taggable="select.multiple"
    //- )
</template>

<script>
import { multiselectHandler } from '@/js/helpers/multiselect'

export default {
  name: 'filter-item',
  props: {
    select: Object,
    params: Object,
  },
  data() {
    return {
      option: {
        value: {},
      },
    }
  },
  mounted() {
    this.option.value = this.normalizeOption(this.params)
  },
  watch: {
    select: {
      handler() {
        this.option.value = this.normalizeOption(this.params)
      },
      deep: true,
    },
    params: {
      handler(nval) {
        this.option.value = this.normalizeOption(nval)
      },
    },
    option: {
      handler(nval) {
        if (null === nval) {
          this.options.value = []
        }
      },
      deep: true,
    },
  },
  methods: {
    normalizeOption(paramsObj) {
      const arr = []
      Object.keys(paramsObj).forEach(key => {
        if (key === this.select.label) {
          paramsObj[key].forEach(el => {
            const res = this.select.options.find(item => item.label === el)
            arr.push(res)
          })
        }
      })
      return arr
    },
    emit(name) {
      if (undefined === multiselectHandler(this.option.value)[0]) {
        delete this.params[name]
      } else {
        this.params[name] = multiselectHandler(this.option.value)
      }
      this.$emit('update-params', this.params)
      this.option.value = this.normalizeOption(this.params)
    },
  },
}
</script>

<template lang="pug">
  include ./../../../mixins
  div
    +b.icon--type_close.--size_xs.modal-close--common.BUTTON(
      tabindex="-1"
      @click="$emit('close')"
    )
    bem-text.ma--bottom_2sm(
      tag="h2"
      appearance="title"
      color="accent-1"
      variant="bold"
      height="sm"
      size="md"
    ) {{_('Specify the number of days for which you want to activate this services')}}
    validation-observer(
      tag="form"
      ref="observer"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit"
    )
      d-control-input.position--relative.ma--bottom_sm(
        rules="required"
        type="number"
        v-model="formdata.days"
        label-class="form__label"
        class-name="form__input"
        :input-label="_('Days')"
        :required="false"
        name="days"
      )
      d-control-multiselect.ma--bottom_sm(
        :placeholder="_('Select option')"
        v-model="payment"
        :options="paymentOptions"
        label-class="form__label"
        :input-label="_('Payment method')"
        :disabled="disablePayment"
      )
      +b.error-text.ma--bottom_sm.--top_sm.P(
        v-for="error in fieldErrors"
        v-if="showErrors === true"
      ) {{ error }}
      bem-button(
        text="title"
        :variant="['fill-accent-1', 'bold', 'uppercase']"
        border="xs"
        space="sm"
        width="full"
        @event="validateBeforeSubmit()"
        :disable="isLoad"
      ) {{_('ACTIVATE')}}
</template>

<script>
import { managerServicesFreeResource } from '@/js/services/manager'
import FormMixin from './form-mixin'

export default {
  name: 'service-activating-form',
  mixins: [FormMixin],
  props: {
    objectItem: Object,
  },
  data() {
    return {
      formdata: {
        days: '',
      },
      paymentOptions: [
        { title: 'Free', value: 'free' },
        { title: 'Payment by invoice', value: 'invoice' },
      ],
      payment: {},
      disablePayment: false,
      showErrors: false,
      isLoad: false,
    }
  },
  mounted() {
    this.checkFree()
  },
  methods: {
    send(data) {
      this.isLoad = true
      if (this.objectItem.id.length) data.idsFeature = this.objectItem.id
      else data.idsFeature = [this.objectItem.id]
      return managerServicesFreeResource.execute({ id: this.$route.params.idUser }, data)
        .then(res => {
          this.handleResponse({ modal: true, res })
          this.isLoad = false
        })
    },
    checkFree() {
      if (true === this.objectItem.isFree) {
        this.disablePayment = true
        this.payment = { title: 'Free', value: 'free' }
      }
    },
  },
}
</script>

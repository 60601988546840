<template funtional lang="pug">
  include ./../../../mixins
  //- div
  +b.loader-wrapper
    +e.bg(:class="isWhite === true ? 'loader-wrapper__bg--white' : 'loader-wrapper__bg--white'")
    +b.loader
      span
</template>

<script>
export default {
  name: 'preloader',
  props: {
    isWhite: {
      default: () => false,
    },
  },
}
</script>

import {
  settingsUserDetailResource,
  settingsShopDetailResource,
} from '@/js/services/cabinet-settings'
import {
  countriesResource,
} from '@/js/services/common-cabinet'
import { subscriptionsListResource } from '@/js/services/subscriptions'

export const customerCabinet = {
  namespaced: true,
  state: {
    categories: [],
    userInfo: {},
    countries: [],
    shop: {},
    subscriprions: {},
  },
  getters: {
    getCategories: state => state.categories,
    getUserInfo: state => state.userInfo,
    countries: state => state.countries,
    shop: state => state.shop,
    getSubscriprionsList: state => state.subscriprions,
  },
  actions: {
    addCategories(context, payload) {
      context.commit('setCategories', payload)
    },
    getUserRequest({ commit }) {
      return settingsUserDetailResource.execute()
        .then(res => { commit('setUser', res.data.item) })
    },
    getCountriesList({ commit }) {
      return countriesResource.execute()
        .then(res => { commit('setCountries', res.data.items) })
    },
    getStoreInfo({ commit }) {
      return settingsShopDetailResource.execute()
        .then(res => { commit('setShop', res.data.item) })
    },
    getSubscriptions({ commit }, payload) {
      return subscriptionsListResource.execute(payload)
        .then(res => { commit('setSubscriptions', res.data) })
    },
  },
  mutations: {
    setCategories(state, payload) {
      state.categories = payload
    },
    setUser(state, payload) {
      state.userInfo = payload
    },
    setCountries(state, payload) {
      state.countries = payload
    },
    setShop(state, payload) {
      state.shop = payload
    },
    setSubscriptions(state, payload) {
      state.subscriprions = payload
    },
  },
}

<template lang="pug">
  include ./../../../mixins
  div
    row.ma--bottom_3lg.position--relative(space="md-xl")
      preloader(v-if="isLoad")
      +b.filter-btn.ma--center.ma--bottom_sm.u-display--hidden-lg.BUTTON(@click="toggleFilter") {{_('Filter')}}
      cell(cols="12 3-lg")
        +b.card--color_white.--variant_fullheight.filter-wrapper(:class="{'is-active': isShowFilters == true}")
          +b.filter-close.u-display--hidden-lg.BUTTON(@click="toggleFilter")
              bem-icon(
                type="close"
                size="xs"
                color="accent-1"
                variant="right"
              )
          +b.card.--space_2sm.--variant_fullheight
            search-filter(
              :categories="categories"
            )
      cell(cols="12 9-lg")
        //-cards
        row(v-if="!isResponse" space="sm-xxl").ma--bottom_3lg
          slot
        renderer.ma--bottom_2md(
          v-if="isResponse"
          :result="response"
          class-render='g-row g-row--space_sm-xxl'
        )
        row(justify="center")
          cell(cols="12 9-lg 6-xxl")
            pagination(
              v-if="count > perPage"
              class-wrapper="pagination__wrapper--white"
              :is-scroll="true"
              :current='page'
              :total='count'
              :perPage='perPage'
              @page-changed="page = $event, requestForCards($event)"
            )
</template>

<script>
import Preloader from '@/js/components/UiElements/Preloader'
import {
  categoriesSearchResource,
  cardsSearchResource,
} from '@/js/services/search'
import { prefixLanguage } from '@/js/helpers/url'
import SearchFilter from './SearchFilter'
import CatalogMixin from './catalog-mixin'

const one = 1

export default {
  name: 'search-catalog',
  mixins: [
    CatalogMixin,
  ],
  components: {
    SearchFilter,
    Preloader,
  },
  props: {
    pagination: Object,
  },
  data() {
    return {
      categories: [],
      paramsObj: {},
      isLoad: false,
      isResponse: false,
      response: '',
      count: this.pagination.count,
      page: this.pagination.page,
      perPage: this.pagination.perPage,
    }
  },
  mounted() {
    this.checkUrl('search')
    this.getCategories()
  },
  methods: {
    getCategories() {
      return categoriesSearchResource.execute({ search: this.paramsObj.search })
        .then(res => {
          this.categories = res.data.items
        })
    },
    requestForCards(page = one) {
      this.isLoad = true
      return cardsSearchResource.execute({
        search: this.paramsObj.search,
        page,
      })
        .then(res => {
          this.pushHistory()
          this.handleCards(res)
          this.isLoad = false
        })
    },
    pushHistory() {
      const baseUrl = `${prefixLanguage(window.location.origin)}/search/`
      let page
      if (1 < this.page) {
        page = `page/${this.page}/`
      } else {
        page = ''
      }
      window.history.pushState(null, null, `${baseUrl}${this.generateUrl(this.paramsObj)}${page}`)
    },
  },
}
</script>

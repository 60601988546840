<template lang="pug">
  include ./../../../mixins
  div
    +b.pagination__wrapper(:class="classWrapper")
      +e.arrow-left
        template(v-if="hasPrev()")
          +e.arrow--left.icon--type_chevron-left.BUTTON(@click.prevent="changePage(prevPage)" aria-label="prev")
        template(v-else)
          +e.arrow--left.icon--type_chevron-left.is-disabled.A(href="#" aria-label="prev")
      +e.pages
        +e.button.A(v-if="hasFirst()", @click.prevent="changePage(1)") 1
        +e.button.events-none.P(v-if="hasFirst() && current >= 5") ...
        template(v-for="page in pages")
          +e.button.P(@click.prevent="changePage(page)", :class="{ 'is-active': current == page }") {{ page }}
        +e.button.events-none.P(v-if="hasLast() && current + 4 <= totalPages") ...
        +e.button.P(v-if="hasLast()", @click.prevent="changePage(totalPages)") {{ totalPages }}
      +e.arrow-right
        template(v-if="hasNext()")
          +e.arrow.icon--type_chevron-right.BUTTON(@click.prevent="changePage(nextPage)" aria-label="next")
        template(v-else)
          +e.arrow.icon--type_chevron-right.is-disabled.A(href="#" aria-label="next")

</template>

<script>
const one = 1
const zero = 0
export default {
  name: 'pagination',
  data() {
    return {
    }
  },
  props: {
    classWrapper: String,
    current: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 9,
    },
    pageRange: {
      type: Number,
      default: 2,
    },
    isScroll: Boolean,
  },

  computed: {
    pages() {
      const pages = []

      for (let i = this.rangeStart; i <= this.rangeEnd; i++) {
        pages.push(i)
      }

      return pages
    },
    rangeStart() {
      const start = this.current - this.pageRange
      return (start > zero) ? start : one
    },
    rangeEnd() {
      const end = this.current + this.pageRange
      return (end < this.totalPages) ? end : this.totalPages
    },
    totalPages() {
      return Math.ceil(this.total / this.perPage)
    },
    nextPage() {
      return this.current + one
    },
    prevPage() {
      return this.current - one
    },
  },
  methods: {
    hasFirst() {
      return this.rangeStart !== one
    },
    hasLast() {
      return this.rangeEnd < this.totalPages
    },
    hasPrev() {
      return this.current > one
    },
    hasNext() {
      return this.current < this.totalPages
    },
    changePage(page) {
      if (true === this.isScroll) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }
      this.$emit('page-changed', page)
    },
  },
}

</script>

import { createRouter } from '@/js/router'
import { createStore } from '@/js/store'
import { createI18n } from './i18n'

export function createApp({ Vue, Router, Store, I18n }) {
  const router = createRouter({ Vue, Router })
  const store = createStore({ Vue, Store })
  const i18n = createI18n({ Vue, I18n })
  const app = new Vue({
    router,
    store,
    i18n,
    delimiters: ['[[', ']]'],
    data() {
      return {
        True: true,
        False: false,
        None: undefined,
      }
    },
  })
  router.beforeEach((to, from, next) => {
    const userType = window.user_role
    const allowRoute = to.matched.some(route => route.meta.userRole === userType)
    if (allowRoute) {
      next()
    } else {
      next({ name: 'no-permission' })
    }
  })
  return { app, router, store, i18n }
}

import {
  postResource,
  getResource,
  createResource,
} from '@/js/resource/resource'
import PATH from './path'

export const categoriesResource = createResource(
  PATH.COMMON_CABINET.CATEGORIES, getResource
)

export const subcategoriesResource = createResource(
  PATH.COMMON_CABINET.SUBCATEGORIES, postResource
)

export const countriesResource = createResource(
  PATH.COMMON_CABINET.COUNTRIES, getResource
)

export const priceDescriptionResource = createResource(
  PATH.COMMON_CABINET.PRICE_DESCRIPTION, getResource
)

export const attributesResource = createResource(
  PATH.COMMON_CABINET.ATTRIBUTES, postResource
)

export const userListResource = createResource(
  PATH.COMMON_CABINET.USER_LIST, getResource
)

<template lang="pug">
  include ./../../../mixins
  div
    +b.icon--type_close.--size_xs.modal-close--common.BUTTON(@click="$emit('close')")
    bem-text.ma--bottom_2md(
      tag="h2"
      appearance="title"
      :variant="['uppercase', 'bold']"
      size="2md"
      color="accent-1"
    ) {{objectItem.title}}
    bem-text.ma--bottom_sm(
      tag="p"
      color="accent-1"
      height="sm"
      size="sm"
    ) {{ answer }}
    validation-observer(
      tag="form"
      ref="observer"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit"
    )
      +b.form.ma--bottom_2sm(v-if="objectItem.withInput")
        d-control-textarea(
          type="textarea"
          rules="required"
          v-model="formdata.answer"
          class-name="form__textarea"
          label-class="form__label"
          :input-label="_('Your message')"
          :required="true"
          name="message"
        )
      bem-button(
        v-if="objectItem.withInput"
        text="title"
        :variant="['fill-accent-1', 'bold']"
        border="xs"
        space="sm"
        width="full"
        @event="validateBeforeSubmit()"
      ) {{_('SEND')}}
</template>

<script>
import FormMixin from '../Forms/form-mixin'

export default {
  name: 'request-modal',
  mixins: [FormMixin],
  props: {
    objectItem: Object,
  },
  data() {
    return {
      answer: '',
      formdata: {
        answer: '',
      },
    }
  },
  mounted() {
    this.getAnswer()
  },
  methods: {
    getAnswer() {
      return this.objectItem.detailResource.execute({ id: this.objectItem.id })
        .then(res => {
          if (true === this.objectItem.withInput) this.answer = res.data.item.question
          else this.answer = res.data.item.answer
        })
    },
    send(data) {
      data.idQuery = this.objectItem.id
      return this.objectItem.sentResource.execute({}, data)
        .then(res => {
          this.handleResponse({ modal: true, res })
        })
    },
  },
}
</script>

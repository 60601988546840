import VueI18n from 'vue-i18n'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import Components from './js/components'
import 'lazysizes'

lazySizes.cfg.expand = 100

export function install(Vue) {
  Vue.use(VueI18n)
  Vue.use(VueRouter)
  Vue.use(Vuex)
  Vue.use(Components)
}

export default { install }

import {
  managerUserDetailResource,
  managerStoreDetailResource,
} from '@/js/services/manager'

export const managerCabinet = {
  namespaced: true,
  state: {
    currentUser: {},
    currentShop: {},
  },
  getters: {
    user: state => state.currentUser,
    shop: state => state.currentShop,
  },
  actions: {
    getCurrentUser({ commit }, payload) {
      return managerUserDetailResource.execute({}, payload)
        .then(res => { commit('setCurrentUser', res.data.item) })
    },
    getCurrentShop({ commit }, payload) {
      return managerStoreDetailResource.execute({}, payload)
        .then(res => { commit('setCurrentShop', res.data.item) })
    },
  },
  mutations: {
    setCurrentUser(state, payload) {
      state.currentUser = payload
    },
    setCurrentShop(state, payload) {
      state.currentShop = payload
    },
  },
}

<template lang="pug">
  include ./../../../mixins
  div
    +b.action-btn(v-if="isAuth")
      +e.wrapper
        +e.button.icon--type_notification.--color_accent-2.A(:href="link" aria-label="favourite")
        +e.counter {{ countItem }}
    modal-handler(
      v-else
      component-name="registration-form"
      modal-class="modal modal--size_sm modal--container modal--padding_md"
      :object-item="objectItem"
    )
      template(#default="{ handler }")
        +b.action-btn
          +e.wrapper
            +e.button.icon--type_notification.--color_accent-2.BUTTON(@click="handler" aria-label="favourite")
</template>

<script>
import { mapGetters } from 'vuex'
import { djangoToJsBoolean } from '@/js/helpers/utils'
import {
  CUSTOMER_PRODUCT_REQUEST_GOT_PATH,
} from '@/js/components/path'

export default {
  name: 'notification-button',
  props: {
    objectItem: Object,
    count: String,
  },
  data() {
    return {
      isAuth: djangoToJsBoolean(window.is_auth),
      countItem: this.count,
      link: CUSTOMER_PRODUCT_REQUEST_GOT_PATH,
    }
  },
  watch: {
    notificationsCount: {
      handler(nval) {
        this.countItem = nval.got
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('common', [
      'notificationsCount',
    ]),
  },
}
</script>

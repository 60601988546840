import {
  CUSTOMER_DASHBOARD_PATH,
  CUSTOMER_SETTINGS_PATH,
  CUSTOMER_PRODUCTS_PATH,
  CUSTOMER_FINANCE_PATH,
  CUSTOMER_PRODUCT_REQUEST_PATH,
  CUSTOMER_CATALOG_PATH,
  CUSTOMER_SUBSCRIPTIONS_PATH,
  CUSTOMER_FAVORITE_PATH,
  CUSTOMER_TRACKED_PATH,
  MANAGER_USERS_LIST_PATH,
  MANAGER_REQUESTS_LIST_PATH,
  MANAGER_CONTACT_LIST_PATH,
} from '@/js/components/path'

export const routesList = {
  client: [
    {
      title: 'Dashboard',
      value: 'dashboard',
      link: CUSTOMER_DASHBOARD_PATH,
      routeName: 'customer-dashboard',
      img: 'dashboard',
    },
    {
      title: 'Settings',
      value: 'settings',
      link: CUSTOMER_SETTINGS_PATH,
      routeName: 'customer-settings',
      img: 'settings',
      mobile: true,
    },
    {
      title: 'Finance',
      value: 'finance',
      link: CUSTOMER_FINANCE_PATH,
      routeName: 'customer-finance',
      img: 'finance',
    },
    {
      title: 'Products',
      value: 'products',
      link: CUSTOMER_PRODUCTS_PATH,
      routeName: 'customer-products',
      img: 'box',
      mobile: true,
    },
    {
      title: 'Product request',
      value: 'product-request',
      link: CUSTOMER_PRODUCT_REQUEST_PATH,
      routeName: 'customer-request',
      img: 'request',
      mobile: true,
    },
    {
      title: 'Tracked Products',
      value: 'tracked-products',
      link: CUSTOMER_TRACKED_PATH,
      routeName: 'customer-tracked',
      img: 'notification',
    },
    {
      title: 'Catalog',
      value: 'catalog',
      link: CUSTOMER_CATALOG_PATH,
      routeName: 'customer-catalog',
      img: 'list',
      mobile: true,
    },
    {
      title: 'Favorite',
      value: 'favorite',
      link: CUSTOMER_FAVORITE_PATH,
      routeName: 'customer-favorites',
      img: 'star',
      mobile: true,
    },
    {
      title: 'Subscriptions',
      value: 'subscriptions',
      link: CUSTOMER_SUBSCRIPTIONS_PATH,
      routeName: 'customer-subscriptions',
      img: 'mail',
    },
  ],
  manager: [
    {
      title: 'Users list',
      value: 'users-list',
      link: MANAGER_USERS_LIST_PATH,
      routeName: 'manager-user-list',
      img: 'personal',
      mobile: true,
    },
    {
      title: 'Service requests',
      value: 'service-requests',
      link: MANAGER_REQUESTS_LIST_PATH,
      routeName: 'manager-request-list',
      img: 'settings',
      mobile: true,
    },
    {
      title: 'Questions',
      value: 'questions',
      link: MANAGER_CONTACT_LIST_PATH,
      routeName: 'manager-contact-list',
      img: 'question',
      mobile: true,
    },
  ],
}

import './public-path'
import './install-helpers'

import Vue from 'vue'
import I18n from 'vue-i18n'
import Router from 'vue-router'
import Store from 'vuex'
import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'

import '@/js/directives'
import renderer from '@/js/components/renderer'

import { install } from '@/install'
import { createApp } from '@/app'

import './js/validation'
import './styles/index.sass'

Vue.config.productionTip = false

Vue.use(install)

const { app } = createApp({
  Vue,
  Router,
  Store,
  renderer,
  I18n,
})

app.$mount('#app')

extend('international_phone', {
  validate(value) {
    const PHONE_REGEXP = /^\+(?:[()0-9 -]●?){6,15}[0-9]$/
    return PHONE_REGEXP.test(value)
  },
})

extend('url_adress', {
  validate(value) {
    const URL_REGEXP = /[\w_-]+(?:(?:\.[\w_-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/
    return URL_REGEXP.test(value)
  },
})

extend('decimal', {
  validate(value) {
    const DECIMAL_REGEXP = /^([1-9][0-9]{0,9}|0)(\.\d{1,2})?$/
    return DECIMAL_REGEXP.test(value)
  },
})

extend('decimal_dollar', {
  validate(value) {
    const DECIMAL_REGEXP = /^\$([1-9][0-9]{0,9}|0)(\.\d{1,2})?$/
    return DECIMAL_REGEXP.test(value)
  },
})

extend('latin', {
  validate(value) {
    const LATIN_REGEXP = /^([A-Za-z$-/–:,;.-?–{-~!"^_`'@\[\]\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s\d]*)$/g
    return LATIN_REGEXP.test(value)
  },
  message() {
    return app._('The field can contain only latin letters.')
  },
})

extend('required', {
  ...rules.required,
  message() {
    return app._('This field is required.')
  },
})

extend('max', {
  ...rules.max,
  message(value, sec) {
    return `${app._('The field can contain a maximum of')} ${sec.length} ${app._('characters')}`
  },
})

extend('min', {
  ...rules.min,
  message(value, sec) {
    return `${app._('The field must contain at least')} ${sec.length} ${app._('symbols')}`
  },
})

extend('required_if', {
  ...rules.required_if,
  message() {
    return app._('This field is required.')
  },
})

extend('confirmed', {
  ...rules.confirmed,
  message() {
    return app._('Values do not match.')
  },
})

extend('email', {
  ...rules.email,
  message() {
    return app._('The field must be a valid email address.')
  },
})

extend('alpha_spaces', {
  ...rules.alpha_spaces,
  message() {
    return app._('The field can contain only letters.')
  },
})

extend('numeric', {
  ...rules.numeric,
  message() {
    return app._('The field must be a number.')
  },
})

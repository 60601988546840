<template lang="pug">
  include ./../../../mixins
  div
    +b.position--relative.wrap-share
      +b.share-btn.BUTTON(:class="buttonClass" @click="isShow = !isShow")
        bem-text(
          v-if="isBig"
          tag="p"
          appearance="title"
          color="accent-1"
          size="3sm"
          :variant="['uppercase', 'bold']"
        ) {{_('Share')}}
        +b.icon--type_share.--size_sm(:class="isBig ? 'icon--color_accent-1' : 'icon--color_accent-2'")
      +b.share-wrapper(:class="{'is-active': isShow}")
        .g-row.g-row--space_xs.g-row--align_center
          .g-cell
            +e.btn--facebook
              share-network(
                network="facebook"
                :url="url"
                title="Facebook"
              )
                +b.icon--type_facebook.--color_white.--size_sm
          .g-cell
            +e.btn--twitter
              share-network(
                network="twitter"
                :url="url"
                :title="title"
              )
                +b.icon--type_twitter.--color_white.--size_sm
          .g-cell
            +e.btn--viber
              share-network(
                network="viber"
                :url="url"
                :title="title"
              )
                +b.icon--type_viber.--color_white.--size_sm
          .g-cell
            +e.btn--telegram
              share-network(
                network="telegram"
                :url="url"
                :title="title"
              )
                +b.icon--type_telegram.--color_white.--size_sm
          .g-cell
            +e.btn--whatsapp
              share-network(
                network="whatsapp"
                :url="url"
                :title="title"
              )
                +b.icon--type_whatsapp.--color_white.--size_sm
</template>

<script>
export default {
  name: 'ui-social',
  props: {
    buttonClass: String,
    buttonText: String,
    isBig: Boolean,
    url: String,
    title: String,
  },
  data() {
    return {
      isShow: false,
    }
  },
}
</script>

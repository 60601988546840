export default {
  bind(el) {
    window.addEventListener('scroll', () => {
      const offset = window.pageYOffset
      const scrollLength = 100
      if (offset >= scrollLength) {
        el.classList.add('is-active')
      } else {
        el.classList.remove('is-active')
      }
    }, { passive: true })
    el.addEventListener('click', () => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    })
  },
}

import {
  postResource,
  createResource,
  getResource,
  // patchResource,
} from '@/js/resource/resource'
import PATH from './path'

export const requestAddResource = createResource(
  PATH.REQUEST.REQUEST_ADD, postResource
)

export const requestSentListResource = createResource(
  PATH.REQUEST.REQUEST_SENT_LIST, getResource
)

export const requestGotListResource = createResource(
  PATH.REQUEST.REQUEST_GOT_LIST, getResource
)

export const requestDetailResource = createResource(
  PATH.REQUEST.REQUEST_DETAIL, getResource
)

export const requestAnswerResource = createResource(
  PATH.REQUEST.REQUEST_ANSWER, postResource
)

export const requestIsReadResource = createResource(
  PATH.REQUEST.REQUEST_IS_READ, postResource
)

export const requestIsReadAnswerResource = createResource(
  PATH.REQUEST.REQUEST_IS_READ_ANSWER, postResource
)

export const requestNotificationsResource = createResource(
  PATH.REQUEST.REQUEST_NOTIFICATION, getResource
)

<template lang="pug">
  include ./../../../mixins
  div
    component(
      :is="component"
      :object-item="this.objectItem"
      @close="closeHandler"
      @action="$emit('action')"
    )
</template>

<script>
export default {
  name: 'common-modal',
  props: {
    component: {
      required: false,
    },
    objectItem: {
      required: false,
    },
  },
  methods: {
    closeHandler() {
      this.$modal.hide('modal')
    },
  },
}
</script>

import { routes as CustomerRoutes } from '@/js/components/Cabinet/Customer/router'
import { routes as ManagerRoutes } from '@/js/components/Cabinet/Manager/router'

const routes = [
  ...CustomerRoutes,
  ...ManagerRoutes,
]

export function createRoutes() {
  return routes
}

export function createRouter({ Router, Vue }) {
  return new Router({
    mode: 'history',
    base: '/',
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      }

      return {
        x: window.pageXOffset,
        y: window.pageYOffset,
      }
    },
    routes: createRoutes({ Router, Vue }),
  })
}

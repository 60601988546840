import CompareButton from './CompareButton'
import FavoriteButton from './FavoriteButton'
import NotificationButton from './NotificationButton'

const LogOut = () => import('./LogOut')
const FavoriteAction = () => import('./FavoriteAction')
const AddCompareAction = () => import('./AddCompareAction')
const DeleteCompareAction = () => import('./DeleteCompareAction')

export default function install(Vue) {
  Vue.component(CompareButton.name, CompareButton)
  Vue.component(FavoriteButton.name, FavoriteButton)
  Vue.component(NotificationButton.name, NotificationButton)
  Vue.component('LogOut', LogOut)
  Vue.component('FavoriteAction', FavoriteAction)
  Vue.component('AddCompareAction', AddCompareAction)
  Vue.component('DeleteCompareAction', DeleteCompareAction)
}

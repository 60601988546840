import SuccessModal from '@/js/components/Modals/SuccessModal'

const messageGetter = x => x.message

export function defaultValidatorErrorsParser(errors) {
  return Object.keys(errors).reduce((acc, x) => {
    acc[x] = errors[x].map(messageGetter)
    return acc
  }, {})
}

export default {
  data() {
    return {
      data: {},
      isLoad: false,
      fieldErrors: [],
    }
  },
  methods: {
    catchFormErrors(promise) {
      return promise.catch(e => {
        const clientError = 400
        const serverError = 500
        if (!e.status || clientError > e.status || serverError <= e.status) {
          throw e
        }
        if (!this.updateValidator) {
          return e
        }
        return e.json().then(body => {
          const errors = {}
          body.errors.forEach(error => {
            if ('request' === error.domain && error.state) {
              Object.assign(errors, error.state)
            }
          })
          this.updateValidator(errors)
          return e
        })
      })
    },
    submit(isValid) {
      if (!isValid) {
        return Promise.reject()
      }
      this.isLoad = true
      return this.catchFormErrors(this.send(this.formdata))
        .finally(() => {
          this.isLoad = false
        })
    },

    async validateBeforeSubmit() {
      const isValid = await this.$refs.observer.validate()
      if (isValid && this.formdata.captcha && '' !== this.formdata.captcha) {
        this.submit(isValid)
        window.grecaptcha.reset()
      } else if (isValid) {
        this.submit(isValid)
      } else {
        this.isLoad = false
        const errArray = document.querySelectorAll('.control-hint')
        errArray[0].scrollIntoView({ block: 'center', behavior: 'smooth' })
      }
    },

    handleResponse(response) {
      Object.keys(this.formdata).forEach(key => {
        this.formdata[key] = ''
      })
      if (response.modal) {
        this.$emit('close')
        // console.log('handleResponse -> response.res.data.item.title', response.res.data.item.title)
        this.showModal(
          response.res.data.item.message.title,
          response.res.data.item.message.text
        )
      }
      if (response.redirect) {
        window.location.replace(response.res.data.item.location)
      }
      this.$refs.observer.reset()
    },

    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.$refs.observer.setErrors(parsed)
      this.fieldErrors = parsed.nonFieldErrors
    },

    showModal(title, text) {
      this.$modal.show(SuccessModal, {
        title,
        text,
      },
      {
        name: 'success-modal',
        classes: 'modal modal--container modal--size_sm modal--padding_lg',
      }, {
        'before-close': () => { this.$emit('action') },
      })
    },
  },
}

<template lang="pug">
  include ./../../../mixins
  div
    +b.mobile-cabinet-nav.u-display--hidden-xl(v-if="isFixed")
      row(justify="center" appearance="nowrap")
        cell(v-for="(item, index) in paths[role]" v-if="item.mobile" :key="item.routeName")
          +e.item.g-row.A(
            :href="item.link"
            :class="{'is-disable': !item.available}"
            :key="index"
          )
            //- :active-class='activeClass'
            cell(cols="12")
              +e.icon.ma--bottom_2xs.BEM-ICON(
                :class="iconClass"
                :type="item.img"
                size="md"
              )
            cell(cols="12")
              +e.link(
                :class="linkClass"
              ) {{ item.title }}
    +b.cabinet-nav(v-if="isFixed === false")
      +e.item.A(
        v-for="(item, index) in paths[role]"
        :href="item.link"
        :key="index"
      )
        //- :active-class='activeClass'
        //- :class="[{'is-disable': !item.available}, sidebarClass]"
        row.width_full(align="center" justify="between" space="none")
          cell(cols="auto")
            row(appearance="nowrap" align="center")
              +e.icon.BEM-ICON(
                :class="iconClass"
                :type="item.img"
                :size="item.value === 'subscriptions' ? '2sm' : 'md'"
              )
              +e.link.text--height_sm(
                :class="linkClass"
              ) {{ item.title }}
          cell(cols="narrow" v-if="item.notificationName")
            bem-text(
              tag="p"
              size="2xs"
              color="darkyellow"
            ) {{ item.notificationName }} &nbsp;
              span.text--color_accent-2.text--variant_bold {{ item.count }}

</template>

<script>
import { routesList } from '@/js/router/routes-list'

export default {
  name: 'cabinet-navigation-outside',
  props: {
    linkClass: String,
    iconClass: String,
    activeClass: String,
    sidebarClass: String,
    isFixed: Boolean,
  },
  data() {
    return {
      paths: routesList,
      role: window.user_role,
    }
  },
}
</script>

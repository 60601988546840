import { mobileMenuToggler } from './mobile-menu'
import tabsHandler from './tabs'
import toggleHandler from './toggle'
import { accordionHandler } from './accordion'
import { setTableWrap } from './table-wrap'
import { setHeight } from './height'

document.addEventListener('DOMContentLoaded', () => {
  mobileMenuToggler()
  tabsHandler()
  toggleHandler()
  setTableWrap()
  accordionHandler()
  setHeight()
}, { passive: true })

document.addEventListener('resize', () => {
  setHeight()
}, { passive: true })

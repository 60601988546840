<template lang="pug">
  include ./../../../mixins
  div
    row.--bottom_3lg-xl(space="md-xxl")
      +b.filter-btn.ma--center.ma--bottom_sm.u-display--hidden-lg.BUTTON(@click="toggleFilter") {{_('Filter')}}
      cell(cols="12 3-lg")
        +b.card--color_white.--variant_fullheight.filter-wrapper(:class="{'is-active': isShowFilters}")
          +b.card.--space_sm-vertical.u-display--hidden-lg
            +b.filter-close.BUTTON(@click="toggleFilter")
              bem-icon(
                type="close"
                size="xs"
                color="accent-1"
                variant="right"
              )
          +b.card.--space_2sm
            row(justify="end")
              cell
                bem-button.ma--top_sm(
                  block="link"
                  variant="underline"
                  color="accent-2"
                  size="3xs"
                  @event="clear()"
                ) {{_('See all catalogs')}}
            filter-section(
              :filter-data="filterData"
              :params="paramsObj"
              @update-params="paramsObj = $event; requestForCards()"
            )
      cell(cols="12 9-lg")
        row(space="2sm-xxl" v-if="!isResponse")
          slot.ma--bottom_lg-xl
        renderer.ma--bottom_lg-xl(
          v-if="isResponse"
          class-render='g-row g-row--space_sm-xxl'
          :result="response"
        )
        row(justify="center")
          cell(cols="12 9-lg 6-xxl")
            pagination.ma--top_md(
              v-if="count > perPage"
              class-wrapper="pagination__wrapper--white"
              :current='page'
              :is-scroll="true"
              :total='count'
              :perPage='perPage'
              @page-changed="page = $event, requestForCards($event)"
            )
</template>

<script>
import {
  pdfFilterResource,
  pdfCardsResource,
  pdfCardsEmptyResource,
} from '@/js/services/catalog-pdf'

import { prefixLanguage } from '@/js/helpers/url'
import FilterSection from './FilterSection'
import CatalogMixin from './catalog-mixin'

const one = 1

export default {
  name: 'pdf-catalog',
  mixins: [
    CatalogMixin,
  ],
  components: {
    FilterSection,
  },
  props: {
    pagination: Object,
  },
  data() {
    return {
      paramsObj: {},
      filterData: [],
      isResponse: false,
      response: '',
      isLoad: false,
      count: this.pagination.count,
      page: this.pagination.page,
      perPage: this.pagination.perPage,
    }
  },
  created() {
    window.onpopstate = async () => {
      window.location.reload()
    }
  },
  mounted() {
    this.checkUrl('catalog-file')
    this.getFilters()
  },
  methods: {
    getFilters() {
      return pdfFilterResource.execute({
        category: this.category,
        filters: this.paramsObj,
        page: this.page,
      })
        .then(res => {
          this.filterData = this.formatFilters(res.data.items)
        })
    },
    requestForCards(page = one) {
      let cardsResource = pdfCardsResource
      if (0 === Object.keys(this.paramsObj).length) {
        cardsResource = pdfCardsEmptyResource
      }
      this.isLoad = true
      return cardsResource.execute({
        category: this.category,
        filters: this.paramsObj,
        page,
      })
        .then(res => {
          this.handleCards(res)
          this.pushHistory()
          this.isLoad = false
        })
    },
    pushHistory() {
      const baseUrl = `${prefixLanguage(window.location.origin)}/catalog-file/`
      let page
      if (1 < this.page) {
        page = `page/${this.page}/`
      } else {
        page = ''
      }
      window.history.pushState(null, null, `${baseUrl}${this.generateUrl(this.paramsObj)}${page}`)
    },
    clear() {
      this.paramsObj = {}
      this.requestForCards()
    },
  },
}
</script>

import {
  getResource,
  createResource,
} from '@/js/resource/resource'
import PATH from './path'

export const pdfFilterResource = createResource(
  PATH.CATALOG_PDF.PDF_FILTERS, getResource
)

export const pdfFilterEmptyResource = createResource(
  PATH.CATALOG_PDF.PDF_FILTERS_EMPTY, getResource
)

export const pdfCardsResource = createResource(
  PATH.CATALOG_PDF.PDF_LIST, getResource
)

export const pdfCardsEmptyResource = createResource(
  PATH.CATALOG_PDF.PDF_LIST_EMPTY, getResource
)
